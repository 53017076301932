import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'store/api/customFetchBase';
import { ReportType } from 'store/api/types';
import {
  DriverReport,
  DriverReportParams,
  ResourceReport,
  ResourceReportParams,
} from 'types/deviceInfoReport';
import {
  AllFeedbacksRequestParams,
  ChangeFeedbackRequest,
  Feedback,
  FeedbackApiResponse,
} from 'types/feedback';
import {
  TireReportItemApiResponse,
  TireReportParams,
  TireReportPatchApiRequest,
  TiresReportApiResponse,
} from 'types/tiresReport';

export const adminApiSlice = createApi({
  reducerPath: 'adminApi',
  baseQuery: customFetchBase,
  tagTypes: ['FeedbackDetail', 'AllFeedbacks', 'AllTireReports'],
  endpoints: (builder) => {
    return {
      changeFeedbackStatusOrReply: builder.mutation<
        Feedback,
        ChangeFeedbackRequest
      >({
        query: ({ feedbackId, reply, status }) => {
          const body: Omit<ChangeFeedbackRequest, 'feedbackId'> = { status };

          if (reply !== undefined && reply !== null) {
            body.reply = reply;
          }
          return {
            url: `/reports/feedback/${feedbackId}`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'FeedbackDetail', arg: arg.feedbackId },
          'AllFeedbacks',
        ],
      }),

      feedbackById: builder.query<Feedback, { feedbackId: number }>({
        query({ feedbackId }) {
          return {
            url: `/reports/feedback/${feedbackId}`,
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: (result, error, arg) => [{ type: 'FeedbackDetail', arg }],
      }),

      allFeedbacks: builder.query<
        FeedbackApiResponse,
        AllFeedbacksRequestParams
      >({
        query(args) {
          const {
            order_by,
            page,
            size,
            resourceFilter,
            driverFilter,
            deviceFilter,
            androidFilter,
            statusFilter,
            startDate,
            endDate,
          } = args;

          const params = new URLSearchParams();
          params.append('order_by', order_by);
          params.append('page', String(page));
          params.append('size', String(size));

          if (resourceFilter?.length) {
            resourceFilter.forEach((filter) =>
              params.append('resource', filter),
            );
          }

          if (driverFilter?.length) {
            driverFilter.forEach((filter) => params.append('driver', filter));
          }

          if (deviceFilter?.length) {
            deviceFilter.forEach((filter) => params.append('device', filter));
          }

          if (androidFilter?.length) {
            androidFilter.forEach((filter) => params.append('android', filter));
          }

          if (statusFilter?.length) {
            statusFilter.forEach((filter) => params.append('status', filter));
          }

          if (startDate) {
            params.append('from_date', startDate);
          }

          if (endDate) {
            params.append('to_date', endDate);
          }

          return {
            url: '/reports/feedback/',
            credentials: 'include',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: ['AllFeedbacks'],
      }),

      allTireReports: builder.query<TiresReportApiResponse, TireReportParams>({
        query(args) {
          const {
            order_by,
            page,
            size,
            resource_number,
            driver,
            licence_plate,
            subcontractor_id,
          } = args;

          const params = new URLSearchParams();
          params.append('order_by', order_by);
          params.append('page', String(page));
          params.append('size', String(size));
          params.append('subcontractor_id', String(subcontractor_id));

          if (resource_number?.length) {
            resource_number.forEach((filter) =>
              params.append('resource_number', filter),
            );
          }

          if (driver?.length) {
            driver.forEach((filter) => params.append('driver', filter));
          }

          if (licence_plate?.length) {
            licence_plate.forEach((filter) =>
              params.append('licence_plate', filter),
            );
          }

          return {
            url: '/reports/tire-report/',
            credentials: 'include',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: ['AllTireReports'],
      }),

      tireReportById: builder.query<
        TireReportItemApiResponse,
        { reportId: number }
      >({
        query({ reportId }) {
          return {
            url: `/reports/tire-report/${reportId}/`,
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      editTireReportById: builder.mutation<
        TireReportItemApiResponse,
        TireReportPatchApiRequest
      >({
        query({ report_id, ...body }) {
          return {
            url: `/reports/tire-report/${report_id}/`,
            credentials: 'include',
            method: 'PATCH',
            body,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        invalidatesTags: ['AllTireReports'],
      }),

      downloadReport: builder.mutation<
        Blob,
        { report_type: ReportType; subcontractor_id: string }
      >({
        query: ({ report_type, subcontractor_id }) => ({
          url: `/reports/export/${report_type}`, // Replace with your endpoint
          method: 'GET',
          params: { subcontractor_id },
          responseHandler: (response) => response.blob(), // Handle as a Blob
        }),
      }),

      driversReport: builder.query<DriverReport, DriverReportParams>({
        query(args) {
          const {
            order_by,
            page,
            size,
            resource_id,
            android,
            device,
            driver,
            from_date,
            to_date,
          } = args;

          const params = new URLSearchParams();
          params.append('order_by', order_by);
          params.append('page', String(page));
          params.append('size', String(size));

          if (android?.length) {
            android.forEach((filter) => params.append('android', filter));
          }

          if (device?.length) {
            device.forEach((filter) => params.append('device', filter));
          }

          if (driver?.length) {
            driver.forEach((filter) => params.append('driver', filter));
          }

          if (from_date) {
            params.append('from_date', from_date);
          }

          if (to_date) {
            params.append('to_date', to_date);
          }

          return {
            url: `/reports/resources/${resource_id}`,
            credentials: 'include',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      ResourcesReport: builder.query<ResourceReport, ResourceReportParams>({
        query(args) {
          const { order_by, page, size, subcontractor_id, resource_number } =
            args;

          const params = new URLSearchParams();
          params.append('page', String(page));
          params.append('size', String(size));

          if (order_by) {
            params.append('order_by', order_by);
          }

          if (subcontractor_id) {
            params.append('subcontractor_id', subcontractor_id);
          }

          if (resource_number?.length) {
            resource_number.forEach((filter) =>
              params.append('resource_number', filter),
            );
          }

          return {
            url: '/reports/resources/',
            credentials: 'include',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),
    };
  },
});

export const {
  useDriversReportQuery,
  useResourcesReportQuery,
  useAllFeedbacksQuery,
  useChangeFeedbackStatusOrReplyMutation,
  useFeedbackByIdQuery,
  useAllTireReportsQuery,
  useTireReportByIdQuery,
  useDownloadReportMutation,
  useEditTireReportByIdMutation,
} = adminApiSlice;
