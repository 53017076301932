import React, { FC, useCallback, useMemo, useState } from 'react';
import { Autocomplete, Button, Chip, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setToast } from 'store/reducers/settingsSlice';
import {
  removeTireFilters,
  updateTireDriverFilters,
  updateTireLicenceFilters,
  updateTirePage,
  updateTireResourceFilters,
  updateTireSize,
} from 'store/reducers/tireReportSlice';
import {
  getTireDriverFilter,
  getTireLicenceFilter,
  getTireResourceFilter,
} from 'store/selectors/tireReport';
import { t } from 'ttag';

import { styles } from './styles';

interface SetResourcesFiltersPopoverProps {
  onClosePopover: () => void;
}

const SetResourcesFiltersPopover: FC<SetResourcesFiltersPopoverProps> = ({
  onClosePopover,
}) => {
  const dispatch = useAppDispatch();
  const driverFilter = useAppSelector(getTireDriverFilter);
  const resourceFilter = useAppSelector(getTireResourceFilter);
  const licenceFilter = useAppSelector(getTireLicenceFilter);

  const [driverFiltersInputValue, setDriverFiltersInputValue] =
    useState<string[]>(driverFilter);

  const [resourceFiltersInputValue, setResourceFiltersInputValue] =
    useState<string[]>(resourceFilter);

  const [licenceFiltersInputValue, setLicenceFiltersInputValue] =
    useState<string[]>(licenceFilter);

  const isAnyFilters = useMemo(() => {
    return (
      driverFilter?.length || resourceFilter?.length || licenceFilter?.length
    );
  }, [driverFilter, resourceFilter, licenceFilter]);

  const isAnyDataInInputs = useMemo(() => {
    return (
      isAnyFilters ||
      driverFiltersInputValue.length ||
      resourceFiltersInputValue.length ||
      licenceFiltersInputValue.length
    );
  }, [
    isAnyFilters,
    driverFiltersInputValue,
    resourceFiltersInputValue,
    licenceFiltersInputValue,
  ]);

  const handleChangeDrivers = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = driverFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setDriverFiltersInputValue(updatedValues);
    },
    [driverFiltersInputValue],
  );

  const handleChangeResources = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = resourceFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setResourceFiltersInputValue(updatedValues);
    },
    [resourceFiltersInputValue],
  );

  const handleChangeLicences = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = licenceFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setLicenceFiltersInputValue(updatedValues);
    },
    [licenceFiltersInputValue],
  );

  const handleApplyPress = useCallback(() => {
    let emptyCounter = 0;

    if (!driverFiltersInputValue?.length) {
      dispatch(updateTireDriverFilters([]));
      emptyCounter++;
    }

    if (!licenceFiltersInputValue?.length) {
      dispatch(updateTireLicenceFilters([]));
      emptyCounter++;
    }

    if (!resourceFiltersInputValue?.length) {
      dispatch(updateTireResourceFilters([]));
      emptyCounter++;
    }

    if (emptyCounter === 5) {
      return;
    }

    dispatch(updateTireDriverFilters(driverFiltersInputValue));
    dispatch(updateTireLicenceFilters(licenceFiltersInputValue));
    dispatch(updateTireResourceFilters(resourceFiltersInputValue));

    dispatch(updateTireSize(10));
    dispatch(updateTirePage(1));

    onClosePopover();
  }, [
    onClosePopover,
    driverFiltersInputValue,
    licenceFiltersInputValue,
    resourceFiltersInputValue,
  ]);

  const handleResetAllPress = useCallback(() => {
    dispatch(removeTireFilters());
    onClosePopover();
  }, [onClosePopover]);

  // @ts-ignore
  return (
    <Box sx={styles.container}>
      <Autocomplete
        value={driverFiltersInputValue}
        multiple
        id="drivers"
        onChange={handleChangeDrivers}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Driver's name`}
            // placeholder={t`Search by driver's name`}
          />
        )}
        sx={styles.firstFilter}
      />

      <Autocomplete
        value={licenceFiltersInputValue}
        multiple
        id="devices"
        onChange={handleChangeLicences}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Licence plate`}
            // placeholder={t`Search by device info`}
          />
        )}
        // sx={styles.firstFilter}
      />

      <Autocomplete
        value={resourceFiltersInputValue}
        multiple
        id="android"
        onChange={handleChangeResources}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Resource number`}
            // placeholder={t`Search by android version`}
          />
        )}
        sx={styles.firstFilter}
      />

      <Button
        disabled={
          driverFiltersInputValue.length === 0 &&
          resourceFiltersInputValue.length === 0 &&
          licenceFiltersInputValue.length === 0
        }
        sx={styles.applyButton}
        variant="contained"
        onClick={handleApplyPress}
      >
        {t`Apply`}
      </Button>
      <Button
        disabled={!isAnyDataInInputs}
        sx={styles.button}
        variant="outlined"
        onClick={handleResetAllPress}
      >
        {t`Reset all`}
      </Button>
    </Box>
  );
};

export { SetResourcesFiltersPopover };
