export const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priorityTextBlock: {
    position: 'absolute',
    top: 5,
    left: 5,
  },
  priorityText: {
    fontSize: '8px',
    lineHeight: '8px',
    fontWeight: 600,
    color: 'custom.white',
  },
};
