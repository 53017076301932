import { FC, useMemo } from 'react';
import { Box, Icon, IconProps, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as TriangleIcon } from 'assets/icons/triangle.svg';
import { ErrandPriorityType } from 'types/api';
import { OrderStatuses, OrderTypes } from 'types/orders';

import { PriorityToken } from 'components/ui/PriorityToken';

import { styles } from './styles';

interface OrdersSectionListItemIconProps {
  status: OrderStatuses;
  type: OrderTypes;
  label: string | number | null;
  isNew: boolean;
  isGrouped: boolean;
  isCurrent: boolean | undefined;
  priority?: ErrandPriorityType | null;
}

interface CollapsibleIconProps extends IconProps {
  label: string | number | null;
}

interface TextIconProps {
  label: string | number | null;
}

const CollapsibleIcon: FC<CollapsibleIconProps> = ({ label, ...rest }) => {
  return <Icon {...rest}>{label}</Icon>;
};

const TextIcon: FC<TextIconProps> = ({ label }) => (
  <Typography sx={styles.iconTextContent}>{label}</Typography>
);

export const OrdersSectionListItemIcon: FC<OrdersSectionListItemIconProps> = ({
  status,
  type,
  label,
  isGrouped,
  isCurrent,
  priority,
}) => {
  const isExecuted =
    status === OrderStatuses.DONE || status === OrderStatuses.FAILED;
  const iconLabel = label || '?';
  const getIconData = (status: OrderStatuses, current: boolean | undefined) => {
    switch (status) {
      case OrderStatuses.DONE:
        return {
          color: 'custom.green',
          children: <TextIcon label={iconLabel} />,
          // children: <Done sx={styles.iconSvgContent} />,
        };
      case OrderStatuses.FAILED:
        return {
          color: 'custom.red',
          children: <TextIcon label={iconLabel} />,
          // children: <Close sx={styles.iconSvgContent} />,
        };
      default:
        if (current) {
          return {
            color: 'primary.main',
            children: <TextIcon label={iconLabel} />,
          };
        }
        return {
          color: 'custom.greyLight',
          children: <TextIcon label={iconLabel} />,
        };
    }
  };

  const iconBorderStyle = useMemo(() => {
    if (isExecuted) {
      return {};
    }

    if (priority === '0') {
      return { borderColor: 'none', border: '1px solid #DA291C' };
    }

    if (priority === '1') {
      return { borderColor: 'none', border: '1px solid #FF8200' };
    }

    if (priority === '2') {
      return { borderColor: 'none', border: '1px solid #42A146' };
    }

    if (priority === '3') {
      return { borderColor: 'none', border: '1px solid #1FD655' };
    }

    return {};
  }, [isExecuted, priority]);

  const { color: iconColor, children } = getIconData(status, isCurrent);

  const iconComponent = useMemo(() => {
    return isGrouped ? (
      <CollapsibleIcon
        sx={{
          ...styles.collapsibleIcon,
          bgcolor: iconColor,
          ...iconBorderStyle,
        }}
        label={iconLabel}
      />
    ) : (
      <Icon sx={{ ...styles.icon, bgcolor: iconColor, ...iconBorderStyle }}>
        {children}
      </Icon>
    );
  }, [isGrouped, iconColor, children, iconBorderStyle]);

  return (
    <Box sx={{ ...styles.iconContainer, color: iconColor }}>
      {type === OrderTypes.PICK && (
        <SvgIcon sx={styles.leftTriangle} component={TriangleIcon} />
      )}
      {iconComponent}
      {type === OrderTypes.DROP && (
        <SvgIcon sx={styles.rightTriangle} component={TriangleIcon} />
      )}
      {!!priority && priority !== '-1' && !isExecuted && (
        <Box sx={styles.priorityBlock}>
          <PriorityToken priority={priority} />
        </Box>
      )}
    </Box>
  );
};
