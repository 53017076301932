import { useMemo } from 'react';
import { MAP_POINT_TYPE } from 'constants/common';
import { find, isEmpty, sortBy } from 'lodash';
import { useUnreadMessagesForMapByResourceQuery } from 'store/api/chatApiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getResourcesListSelector,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';
import { getIsShowDoneFailShipments } from 'store/selectors/settings';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';
import { ErrandPriorityType, UnreadByShipment } from 'types/api';
import { ChatPriority } from 'types/dedicatedChat';
import { Order, OrderStatuses } from 'types/orders';
import { Coordinates, DriverDetails } from 'types/resource';

import { useOrdersList } from './useOrdersList';

export type PointStatus =
  | 'done'
  | 'fail'
  | undefined
  | 'done-fail'
  | 'done-fail-pending'
  | 'done-pending'
  | 'fail-pending';

export interface MapPointPopupData {
  id: string;
  name: string;
  status: OrderStatuses;
  orderNumber: number;
  priority?: ErrandPriorityType | null;
}

export interface OrderWithUnread extends Order {
  unreadMessages?: UnreadByShipment;
}

export type CommonUnreadMessages = {
  priority?: ChatPriority;
  count?: number;
};

export interface MapPoint {
  id: string;
  type?: MAP_POINT_TYPE;
  coords: Coordinates;
  driverId?: string;
  details?: DriverDetails;
  resourceNumber?: string;
  status?: PointStatus;
  order?: number;
  executedOrder?: number;
  orderData?: OrderWithUnread[];
  insertedOrdersQty?: number;
  popupTitle?: string;
  popupSubtitle?: string;
  popupData?: MapPointPopupData[];
  orderNumber?: number;
  commonUnreadMessages?: CommonUnreadMessages;
  priority?: ErrandPriorityType | null;
}

type GroupedMapPointByAddressId = Record<string, MapPoint> | undefined;

export const useResourceRouteData = () => {
  const resources = useAppSelector(getResourcesListSelector);
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const { ordersList: data } = useOrdersList();
  const isShowDoneFail = useAppSelector(getIsShowDoneFailShipments);

  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);

  const resourceName = useMemo(() => {
    if (!selectedResourceId) {
      return null;
    }

    const foundedResource = resources?.find(
      (resource) => resource.id === selectedResourceId,
    );

    if (!foundedResource) {
      return null;
    }

    return foundedResource.number;
  }, [resources, selectedResourceId]);

  const { data: allRoutesData } = useUnreadMessagesForMapByResourceQuery(
    {
      resource_name: resourceName,
    },
    { skip: !resourceName, refetchOnMountOrArgChange: true },
  );

  const serializedUnreadMessagesData = useMemo(() => {
    const currentResourceAndWorkshiftData = allRoutesData?.find(
      (route) =>
        route.resource_id === selectedResourceId &&
        route.work_shift_id === selectedWorkshiftId,
    );

    if (!currentResourceAndWorkshiftData) {
      return null;
    }

    // @ts-ignore
    if (!currentResourceAndWorkshiftData?.points?.length) {
      return null;
    }

    // @ts-ignore
    const result = currentResourceAndWorkshiftData.points.reduce(
      (acc, curr) => {
        if (!curr.unread_by_shipments.length) {
          return acc;
        }
        const pointUnread = curr.unread_by_shipments.reduce(
          (unreadAcc, unreadCurr) => {
            if (!unreadCurr.shipment_id) {
              return unreadAcc;
            }

            return {
              ...unreadAcc,
              [unreadCurr.shipment_id]: unreadCurr,
            };
          },
          {},
        );

        return {
          ...acc,
          ...pointUnread,
        };
      },
      {},
    );

    return isEmpty(result) ? null : result;
  }, [selectedResourceId, selectedWorkshiftId, allRoutesData]);

  const pendingShipments = useMemo(() => {
    return isShowDoneFail
      ? data
      : data?.filter(
          (order) =>
            order.status !== OrderStatuses.FAILED &&
            order.status !== OrderStatuses.DONE,
        );
  }, [isShowDoneFail, data]);

  const resourcePoint: MapPoint | null = useMemo(() => {
    const selectedResource = resources.find((r) => r.id === selectedResourceId);

    if (!selectedResource?.coordinates) {
      return null;
    }

    const nowDate = Date.now();
    const currentShift = find(
      selectedResource.work_shifts,
      (r) => nowDate > Date.parse(r.start) && nowDate < Date.parse(r.end),
    );

    if (!currentShift) {
      return null;
    }

    return {
      id: selectedResource.id,
      coords: selectedResource.coordinates,
      driverId: currentShift.driver_id,
      type: MAP_POINT_TYPE.resource,
      details: selectedResource.details,
      resourceNumber: selectedResource?.number,
    };
  }, [resources, selectedResourceId]);

  // @ts-ignore
  const pointsObject = useMemo(() => {
    if (!pendingShipments) {
      return [];
    }

    return pendingShipments
      .filter((s) => !s.isHidden)
      .reduce(
        (
          accum: GroupedMapPointByAddressId,
          curr: Order,
        ): GroupedMapPointByAddressId => {
          const common = {
            id: curr.id,
            coords: [
              curr?.address?.longitude as number,
              curr?.address?.latitude as number,
            ] as Coordinates,
          };

          if (curr?.address?.id) {
            if (accum?.[curr.address.id]) {
              return {
                ...accum,
                [curr.address.id]: {
                  ...accum[curr.address.id],
                  orderData: [
                    // @ts-ignore
                    ...accum[curr.address.id].orderData,
                    curr,
                  ],
                },
              };
            }

            let orderData = [curr];

            if (curr.isCollapsible && curr?.dropOptions?.length) {
              orderData = curr.dropOptions;
            }

            return {
              ...accum,
              [curr.address.id]: {
                ...common,
                orderData,
                popupTitle: curr.address.title,
                popupSubtitle: curr.address.subtitle,
                orderNumber: curr.orderNumber as number,
              },
            };
          }
        },
        {},
      );
  }, [pendingShipments]);

  const groupedByAddressPoints = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(pointsObject || {}).map(([_, point]) => {
      const isOnlyDone = point?.orderData?.every(
        (p) => p.status === OrderStatuses.DONE,
      );
      const isOnlyFail = point?.orderData?.every(
        (p) => p.status === OrderStatuses.FAILED,
      );
      // const isOnlyPending = point?.orderData?.every(p => p.status === OrderStatuses.PENDING);

      const isSomeDone = point?.orderData?.some(
        (p) => p.status === OrderStatuses.DONE,
      );
      const isSomeFail = point?.orderData?.some(
        (p) => p.status === OrderStatuses.FAILED,
      );
      const isSomePending = point?.orderData?.some(
        (p) => p.status === OrderStatuses.PENDING,
      );

      let status: PointStatus;

      if (isOnlyDone) {
        status = 'done';
      }

      if (isOnlyFail) {
        status = 'fail';
      }

      if (isSomeDone && isSomeFail && isSomePending) {
        status = 'done-fail-pending';
      }

      if (isSomeDone && isSomeFail && !isSomePending) {
        status = 'done-fail';
      }

      if (!isSomeDone && isSomeFail && isSomePending) {
        status = 'fail-pending';
      }

      if (isSomeDone && !isSomeFail && isSomePending) {
        status = 'done-pending';
      }

      const popupData: MapPointPopupData[] = [];

      if (point?.orderData?.length && point?.orderData?.length > 1) {
        point.orderData.forEach((p) => {
          popupData.push({
            id: p.id,
            status: p.status,
            name: p?.subtitle || '',
            orderNumber: p.orderNumber || 0,
            priority:
              p.status !== OrderStatuses.DONE &&
              p.status !== OrderStatuses.FAILED &&
              !p?.isTerminalReturn
                ? p?.bookingData?.priority
                : null,
          });
        });
      }

      const onlyPendingOrders = point?.orderData?.filter(
        (o) =>
          o.status !== OrderStatuses.DONE && o.status !== OrderStatuses.FAILED,
      );

      let priority = null;

      if (
        !onlyPendingOrders?.some(({ id }) => id?.startsWith('terminal')) &&
        !onlyPendingOrders?.some((order) => order?.isTerminalReturn)
      ) {
        const priorities = onlyPendingOrders
          ?.map((p) =>
            p?.bookingData?.priority ? p?.bookingData?.priority : null,
          )
          ?.filter(Boolean);

        if (priorities?.length) {
          priority = String(
            Math.min(...priorities.map(Number)),
          ) as ErrandPriorityType;
        }
      }

      return {
        ...point,
        insertedOrdersQty: point.orderData?.length || 0,
        status,
        popupData,
        priority,
      };
    });
  }, [pointsObject]);

  const mappedShipments: MapPoint[] = useMemo(() => {
    if (!groupedByAddressPoints) {
      return [];
    }

    return sortBy(groupedByAddressPoints, 'orderNumber', 'asc').map(
      (s, idx) => {
        let type = MAP_POINT_TYPE.point;

        if (idx === groupedByAddressPoints.length - 1) {
          type = MAP_POINT_TYPE.last;
        }

        return {
          ...s,
          type,
        };
      },
    );
  }, [groupedByAddressPoints]);

  let currentIndex = 0;
  let currentExecutedIndex = 0;

  const mappedShipmentsWithExecutedOrderNumbers = mappedShipments.reduce(
    (accum: MapPoint[], curr: MapPoint) => {
      if (curr?.status) {
        // if (accum.some((s) => isEqual(s.coords, curr.coords))) {
        //   return [...accum, { ...curr, executedOrder: currentExecutedIndex }];
        // } else {
        //   return [...accum, { ...curr, executedOrder: ++currentExecutedIndex }];
        // }

        const executedOrder = ++currentExecutedIndex;

        const popupDataLengthWithoutPending =
          curr?.popupData?.filter(
            (c) =>
              c.status !== OrderStatuses.PENDING &&
              executedOrder < c.orderNumber,
          )?.length || 0;
        if (popupDataLengthWithoutPending > 1) {
          currentExecutedIndex =
            currentExecutedIndex + popupDataLengthWithoutPending - 1;
        }

        return [...accum, { ...curr, executedOrder }];
      }

      return [...accum, curr];
    },
    [],
  );

  const isLastDoneHasPendingTasks = mappedShipments
    ?.filter((m) => !!m?.status)
    ?.at(-1)
    ?.popupData?.some((s) => s.status === OrderStatuses.PENDING);

  if (isLastDoneHasPendingTasks) {
    ++currentIndex;
  }

  const mappedShipmentsWithOrderNumbers =
    mappedShipmentsWithExecutedOrderNumbers.map((point) => {
      let order;

      if (!point?.status) {
        currentIndex = currentIndex + 1;
        order = currentIndex;
      }

      return {
        ...point,
        order,
      };
    });

  const mapPoints = useMemo(() => {
    if (resourcePoint?.coords) {
      if (isShowDoneFail) {
        if (!mappedShipmentsWithOrderNumbers?.length) {
          return [resourcePoint];
        }

        let isFirstTime = true;

        return mappedShipmentsWithOrderNumbers.reduce(
          (acc: MapPoint[], curr) => {
            if (
              curr?.status !== 'done' &&
              curr?.status !== 'fail' &&
              curr?.status !== 'done-fail' &&
              isFirstTime
            ) {
              isFirstTime = false;

              return [...acc, resourcePoint, curr];
            }

            return [...acc, curr, resourcePoint];
          },
          [],
        );
      }
      return [resourcePoint, ...mappedShipmentsWithOrderNumbers];
    }

    return mappedShipmentsWithOrderNumbers;
  }, [mappedShipmentsWithOrderNumbers, resourcePoint, isShowDoneFail]);

  const mappedShipmentsForLines: MapPoint[] = useMemo(() => {
    if (!pendingShipments) {
      return [];
    }

    const filteredShipments = pendingShipments.filter((s) => !s.isHidden);

    return sortBy(filteredShipments, 'orderNumber', 'asc').map((s, idx) => {
      let type = MAP_POINT_TYPE.point;

      if (idx === pendingShipments.length - 1) {
        type = MAP_POINT_TYPE.last;
      }

      let status: PointStatus;

      if (s.status === OrderStatuses.FAILED) {
        status = 'fail';
      }

      if (s.status === OrderStatuses.DONE) {
        status = 'done';
      }

      return {
        id: s.id,
        coords: [s?.address?.longitude, s?.address?.latitude],
        type,
        status,
      };
    });
  }, [pendingShipments]);

  const pointsFoLines = useMemo(() => {
    if (resourcePoint?.coords) {
      if (isShowDoneFail) {
        let isFirstTime = true;

        return mappedShipmentsForLines.reduce((acc: MapPoint[], curr) => {
          if (
            curr?.status !== 'done' &&
            curr?.status !== 'fail' &&
            // curr?.status !== 'done-fail' &&
            isFirstTime
          ) {
            isFirstTime = false;

            return [...acc, resourcePoint, curr];
          }

          return [...acc, curr];
        }, []);
      }
      return [resourcePoint, ...mappedShipmentsForLines];
    }

    return mappedShipmentsForLines;
  }, [mappedShipmentsForLines, resourcePoint, isShowDoneFail]);

  const linePoints = useMemo(() => {
    return pointsFoLines.map((m) => m.coords);
  }, [mapPoints]);

  const mapPointsWithUnread = useMemo(() => {
    return mapPoints.map((point) => {
      if (
        point?.type === MAP_POINT_TYPE.resource ||
        point?.id?.includes('terminal')
      ) {
        return point;
      }

      const updatedOrderData = point?.orderData?.map((order) => {
        if (serializedUnreadMessagesData?.[order.shipmentId]) {
          return {
            ...order,
            unreadMessages: serializedUnreadMessagesData?.[order.shipmentId],
          };
        }

        return order;
      });

      let countOfOrderDataWithUnread = 0;

      const commonUnreadMessage: CommonUnreadMessages | undefined =
        updatedOrderData?.reduce((acc, curr) => {
          if (curr.unreadMessages) {
            countOfOrderDataWithUnread++;

            return {
              priority: curr.unreadMessages.priority,
              count: (acc as CommonUnreadMessages)?.count
                ? ((acc as CommonUnreadMessages)?.count || 0) +
                  curr.unreadMessages.count
                : curr.unreadMessages.count,
            };
          }

          return acc;
        }, {});

      if (
        countOfOrderDataWithUnread > 1 &&
        commonUnreadMessage &&
        !isEmpty(commonUnreadMessage)
      ) {
        commonUnreadMessage.priority = undefined;
      }

      return {
        ...point,
        orderData: updatedOrderData,
        commonUnreadMessages: isEmpty(commonUnreadMessage)
          ? undefined
          : commonUnreadMessage,
      };
    });
  }, [serializedUnreadMessagesData, mapPoints]);

  return {
    linePoints,
    mapPoints: mapPointsWithUnread,
  };
};
