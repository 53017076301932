import { FC, useMemo } from 'react';
import { useAllRoutesSelectedOrder } from 'hooks/useAllRoutesSelectedOrder';
import { useProofTypeName } from 'hooks/useProofTypeName';
import { useSelectedOrder } from 'hooks/useSelectedOrder';
import { t } from 'ttag';
import {
  AllRoutesSelectedOrder,
  Order,
  OrderAddressTypes,
  OrderStatuses,
  OrderTypes,
} from 'types/orders';
import { removeUndefinedEntriesFromObject } from 'utils/common';

import { CloseDetailButton } from 'components/ui/CloseDetailButton';
import { Section } from 'components/ui/Section';

import { DetailsSectionInnerAdditions } from '../DetailsSectionInnerAdditions';
import { DetailsSectionInnerCodes } from '../DetailsSectionInnerCodes';
import { DetailsSectionInnerDeliveryData } from '../DetailsSectionInnerDeliveryData';
import { DetailsSectionInnerDestination } from '../DetailsSectionInnerDestination';
import { DetailsSectionInnerDetails } from '../DetailsSectionInnerDetails';
import { DetailsSectionInnerFail } from '../DetailsSectionInnerFail';
import { DetailsSectionInnerInstruction } from '../DetailsSectionInnerInstruction';
import { DetailsSectionInnerParcelData } from '../DetailsSectionInnerParcelData';
import { DetailsSectionInnerPickupOrDeliveryExecution } from '../DetailsSectionInnerPickupOrDeliveryExecution';
import { DetailsSectionInnerPickupOrDeliveryPhotos } from '../DetailsSectionInnerPickupOrDeliveryPhotos';
import { DetailsSectionInnerRecipient } from '../DetailsSectionInnerRecipient';
import { DetailsSectionInnerTerminalData } from '../DetailsSectionInnerTerminal';
import { DetailsSectionSkeleton } from '../DetailsSectionSkeleton';

import { styles } from './styles';

interface DetailsSectionProps {
  halfSize: boolean;
  allRoutesSelectedOrder: AllRoutesSelectedOrder | null;
}

export const DetailsSection: FC<DetailsSectionProps> = ({
  halfSize = false,
  allRoutesSelectedOrder,
}) => {
  const { selectedOrder: resourceOrder, isFetching: resourceOrderFetching } =
    useSelectedOrder();
  const { selectedOrder: allRoutesOrder, isFetching: allRoutesOrderFetching } =
    useAllRoutesSelectedOrder(allRoutesSelectedOrder);

  const selectedOrder = useMemo(() => {
    return resourceOrder || allRoutesOrder;
  }, [resourceOrder, allRoutesOrder]);

  const isFetching = useMemo(
    () => resourceOrderFetching || allRoutesOrderFetching,
    [resourceOrderFetching, allRoutesOrderFetching],
  );

  const {
    instruction,
    phoneNumber,
    recipientName,
    intercom,
    enter_code,
    knock_on_the_door,
    elevator,
    floor,
    packaging_return,
    ageControl,
    proofType,
    quiet_delivery,
    quiet_pickup,
    use_doorbell,
  } = selectedOrder?.data || {};

  const {
    freightBill,
    isCollapsible,
    additionsData,
    packages,
    dropOptions,
    bookingNumber,
    time,
    proofData,
    status,
    type,
    address,
    deliveryInstruction,
    deliveryData,
    subtitle,
  } = selectedOrder || {};

  const isTerminalPickOrder =
    type === OrderTypes.PICK && address?.type === OrderAddressTypes.TERMINAL;

  const isRecipientInnerSectionPresent =
    !!recipientName?.trim() || !!phoneNumber;
  const isDetailsInnerSectionPresent =
    intercom ||
    enter_code ||
    knock_on_the_door ||
    elevator ||
    floor ||
    packaging_return ||
    proofType;

  const { proofTypeName } = useProofTypeName(proofType);

  const proofName = proofData?.proof_type;
  const isCancellationReason =
    proofName === 'can_not_find_location' ||
    proofName === 'can_not_enter_the_building' ||
    proofName === 'can_not_find_the_door_of_the_address' ||
    proofName === 'nobody_home' ||
    proofName === 'receiver_is_home_but_delivery_failed' ||
    proofName === 'another_reason';

  const isCodesSectionPresent = Boolean(freightBill || bookingNumber);

  const instructionInnerSection = instruction && (
    <DetailsSectionInnerInstruction
      instruction={instruction}
      deliveryInstruction={deliveryInstruction}
      isPickup={type === OrderTypes.PICK}
    />
  );
  const recipientInnerSection = isRecipientInnerSectionPresent && (
    <DetailsSectionInnerRecipient
      phone={phoneNumber}
      name={recipientName?.trim() || subtitle || undefined}
    />
  );
  const detailsInnerSection = isDetailsInnerSectionPresent && (
    <DetailsSectionInnerDetails
      intercom={intercom}
      enterCode={enter_code}
      knockDoor={knock_on_the_door}
      elevator={elevator}
      floor={floor}
      packagingReturn={packaging_return}
      ageControl={ageControl}
      proofTypeName={proofTypeName}
      quietPickup={quiet_pickup}
      quietDelivery={quiet_delivery}
      useDoorbell={use_doorbell}
    />
  );
  const destinationInnerSection = selectedOrder && (
    <DetailsSectionInnerDestination order={selectedOrder} />
  );
  const codesInnerSection = isCodesSectionPresent && (
    <DetailsSectionInnerCodes
      freightBill={freightBill}
      orderNumber={bookingNumber}
    />
  );

  const additionsInnerSection = additionsData &&
    removeUndefinedEntriesFromObject(additionsData) && (
      <DetailsSectionInnerAdditions {...additionsData} />
    );
  const parcelDataInnerSection = !!packages?.length && (
    <DetailsSectionInnerParcelData parcels={packages} />
  );
  const deliveryDataInnerSection = !!packages?.length && (
    <DetailsSectionInnerDeliveryData packages={packages} {...deliveryData} />
  );
  const ordersInnerSection = !!dropOptions?.length && (
    <DetailsSectionInnerTerminalData
      order={resourceOrder as Order}
      orders={dropOptions}
      beAtTerminalTime={time?.be_terminal_latest || null}
      leaveTerminalTime={time?.leave_terminal_latest || null}
    />
  );

  const isPhotosOrComment =
    proofData?.data?.order_confirmation_photo_urls ||
    proofData?.data?.order_confirmation_comment;
  const pickupOrDeliveryInnerSection = !!isPhotosOrComment &&
    !!selectedOrder && (
      <DetailsSectionInnerPickupOrDeliveryPhotos order={selectedOrder} />
    );

  const isPickupOrDeliveryExecution =
    status === OrderStatuses.DONE && !isTerminalPickOrder;
  const pickupOrDeliveryExecutionInnerSection = isPickupOrDeliveryExecution &&
    !!selectedOrder &&
    !selectedOrder.dropTerminalId && (
      <DetailsSectionInnerPickupOrDeliveryExecution order={selectedOrder} />
    );

  const cancellationReasonSection = isCancellationReason && !!selectedOrder && (
    <DetailsSectionInnerFail order={selectedOrder} />
  );

  const areInnerSectionsExist = Boolean(
    instructionInnerSection ||
      recipientInnerSection ||
      detailsInnerSection ||
      destinationInnerSection ||
      codesInnerSection ||
      additionsInnerSection ||
      parcelDataInnerSection ||
      deliveryDataInnerSection ||
      ordersInnerSection ||
      pickupOrDeliveryInnerSection,
  );

  const getContent = () => {
    if (isFetching) {
      {
        return <DetailsSectionSkeleton quantity={10} />;
      }
    }
    if (isCollapsible) {
      return null;
    }

    if (areInnerSectionsExist) {
      return (
        <>
          {destinationInnerSection}
          {codesInnerSection}
          {ordersInnerSection}
          {recipientInnerSection}
          {detailsInnerSection}
          {instructionInnerSection}
          {additionsInnerSection}
          {deliveryDataInnerSection}
          {parcelDataInnerSection}
          {pickupOrDeliveryInnerSection}
          {pickupOrDeliveryExecutionInnerSection}
          {cancellationReasonSection}
        </>
      );
    }

    return null;
  };

  const content = getContent();

  const sectionStyle = halfSize ? styles.halfContainer : styles.container;

  return (
    <Section
      innerStyles={styles.inner}
      label={t`Details`}
      emptyText={t`Select an order to see the details`}
      sx={sectionStyle}
      buttons={[<CloseDetailButton key="CloseDetail Button" />]}
    >
      {content}
    </Section>
  );
};
