import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  List,
  TablePagination,
  Typography,
} from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import {
  COMMON_SETTING,
  commonSettingMapper,
  ERRAND_SETTINGS,
  errandSettingMapper,
  ETA_SETTING,
  etaSettingMapper,
  setGpsPositionOptions,
} from 'constants/settings';
import { GroupSettingProvider } from 'context/GroupSetting';
import { format } from 'date-fns';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { isEmpty, isEqual, uniqueId } from 'lodash';
import {
  useCommonOptimizationSettingsQuery,
  useResourcesQuery,
  useResourcesSettingsQuery,
  useResourcesV2Query,
  useUpdateCommonOptSettingsMutation,
  useUpdateResourceSettingsMutation,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setToast } from 'store/reducers/settingsSlice';
import {
  getResourceFilterSelector,
  getSelectedResourceIdSelector,
  getSelectedResourceNumberSelector,
} from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getOnlyCurrentWorkshiftSelector,
  getResourcesSettingsFilters,
  getResourcesSettingsGroups,
} from 'store/selectors/settings';
import { getSelectedWorkshiftIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { CommonOptimizationSettings, ResourcesSettings } from 'types/api';
import { setCurrentResourceAsFirst } from 'utils/settingsUtils';

import { ResourceGroupsTab } from 'components/screens/EditSettingsScreen/components/ResourceGroupsTab';
import { SettingsChapter } from 'components/screens/EditSettingsScreen/components/SettingsChapter';
import { Checkbox } from 'components/ui/Checkbox';
import { SelectWithoutBorder } from 'components/ui/Select';

import { styles } from './styles';

const menuOptions = [
  t`Global settings`,
  t`Errand optimization`,
  t`Eta-updates settings`,
  t`Resources groups`,
];

export const EditSettingsScreen: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const selectedWorkshiftId = useAppSelector(getSelectedWorkshiftIdSelector);

  const resourcesSettingsGroups = useAppSelector(getResourcesSettingsGroups);
  const resourcesSettingsFilters = useAppSelector(getResourcesSettingsFilters);

  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const {
    data,
    isSuccess: isResourceSuccess,
    refetch: resourseRefetch,
  } = useResourcesSettingsQuery(
    {
      page: page + 1,
      size,
      current_resource_id: selectedResourceId || undefined,
      resource_number: resourcesSettingsFilters,
      group: resourcesSettingsGroups,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { results: resourceSettingsApiData = null, total: totalResources = 0 } =
    data || {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const resourceFilter = useAppSelector(getResourceFilterSelector);

  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const commonDate = useAppSelector(getCommonDateSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: resourcesListV2 } = useResourcesV2Query(
    {
      resource_name: resourceFilter,
      date: commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd'),
    },
    { skip: onlyCurrentWorkshift, refetchOnMountOrArgChange: true },
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: resourcesList } = useResourcesQuery(resourceFilter, {
    skip: !onlyCurrentWorkshift,
  });
  const {
    data: commonOptSetApiData,
    isSuccess: isCommonOptSetSuccess,
    refetch,
  } = useCommonOptimizationSettingsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [updateCommonOptimizationSettings] =
    useUpdateCommonOptSettingsMutation();
  const [updateResourceSettings] = useUpdateResourceSettingsMutation();

  const [currenMenuItem, setCurrentMenuItem] = useState<number>(0);
  const [resourcesSettings, setResourcesSettings] = useState(
    resourceSettingsApiData || {},
  );
  const [commonSettings, setCommonSettings] = useState(commonOptSetApiData);

  const currentResourceNumber = useAppSelector(
    getSelectedResourceNumberSelector,
  );

  useEffect(() => {
    setPage(0);
    setSize(50);
  }, [resourcesSettingsGroups, resourcesSettingsFilters]);

  useEffect(() => {
    if (resourceSettingsApiData && isResourceSuccess) {
      setResourcesSettings(resourceSettingsApiData);
    }
  }, [resourceSettingsApiData, isResourceSuccess]);

  useEffect(() => {
    if (commonOptSetApiData && isCommonOptSetSuccess) {
      setCommonSettings(commonOptSetApiData);
    }
  }, [commonOptSetApiData, isCommonOptSetSuccess]);

  const handleChangePage = useCallback(
    // @ts-ignore
    (_, newPage: number) => {
      setPage(newPage);
    },
    [],
  );

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const menuList = useMemo(
    () =>
      menuOptions.map((option, index) => (
        <SettingsChapter
          key={`${option} ${index}`}
          isActive={currenMenuItem === index}
          label={option}
          onPress={() => setCurrentMenuItem(index)}
        />
      )),
    [currenMenuItem],
  );

  const listOfFieldNames = useMemo(() => {
    if (currenMenuItem === 0) {
      const result = Object.values(COMMON_SETTING).map(
        (item) => commonSettingMapper[item],
      );

      return [' ', ...result];
    }

    if (currenMenuItem === 1) {
      const result = Object.values(ERRAND_SETTINGS).map(
        (item) => errandSettingMapper[item],
      );

      return [' ', ...result];
    }

    const result = Object.values(ETA_SETTING).map(
      (item) => etaSettingMapper[item],
    );

    return [' ', ...result];
  }, [currenMenuItem]);

  const columnItemData = useMemo(() => {
    if (currenMenuItem === 0) {
      return ['common', ...Object.keys(COMMON_SETTING)];
    }

    if (currenMenuItem === 1) {
      return ['resource', ...Object.keys(ERRAND_SETTINGS)];
    }

    return ['resource', ...Object.keys(ETA_SETTING)];
  }, [currenMenuItem]);

  const handleSaveClick = useCallback(async () => {
    const resourceRequest: ResourcesSettings = {};

    for (const key in resourcesSettings) {
      if (!isEqual(resourcesSettings[key], resourceSettingsApiData?.[key])) {
        resourceRequest[key] = resourcesSettings[key];
      }
    }

    if (
      isEqual(commonOptSetApiData, commonSettings) &&
      isEmpty(resourceRequest)
    ) {
      dispatch(
        setToast({
          message: t`No changes have been added`,
          severity: 'error',
        }),
      );

      return;
    }

    dispatch(
      setToast({
        message: t`You have successfully changed settings`,
        severity: 'success',
      }),
    );
    navigateWithQueryParams(APP_ROUTES.root);

    await Promise.all([
      updateCommonOptimizationSettings({
        commonSettings: commonSettings as CommonOptimizationSettings,
        workshiftId: selectedWorkshiftId,
        resourceId: selectedResourceId as string,
      }),
      updateResourceSettings(resourceRequest),
    ]);

    refetch();
    resourseRefetch();
  }, [
    commonSettings,
    commonOptSetApiData,
    resourcesSettings,
    resourceSettingsApiData,
    selectedWorkshiftId,
    selectedResourceId,
    navigateWithQueryParams,
  ]);

  const rowItemData = useMemo(() => {
    if (currenMenuItem === 0) {
      return ['namedColumn', 'gap', 'common'];
    }

    if (resourceSettingsApiData) {
      return [
        'namedColumn',
        'gap',
        ...setCurrentResourceAsFirst(
          Object.keys(resourceSettingsApiData),
          currentResourceNumber,
        ),
      ];
    }

    return [];
  }, [currenMenuItem, resourceSettingsApiData, currentResourceNumber]);

  const getCurrentCheckBoxValue = useCallback(
    (resourceNumber: string, fieldName: string) => {
      if (currenMenuItem === 0) {
        return commonSettings?.[fieldName as COMMON_SETTING] || false;
      }

      if (currenMenuItem === 1) {
        return (
          resourcesSettings?.[resourceNumber]?.errand_settings?.[
            fieldName as ERRAND_SETTINGS
          ] || false
        );
      }

      if (currenMenuItem === 2) {
        return (
          resourcesSettings?.[resourceNumber]?.eta_settings?.[
            fieldName as ETA_SETTING
          ] || false
        );
      }

      return false;
    },
    [currenMenuItem, commonSettings, resourcesSettings],
  );

  const handleCheckBoxChange = useCallback(
    (newValue: string, resourceNumber: string, fieldName: string) => {
      if (currenMenuItem === 0) {
        if (commonSettings) {
          setCommonSettings((prev) => {
            if (prev) {
              if (fieldName === COMMON_SETTING.send_GPS_position_in_seconds) {
                return {
                  ...prev,
                  [fieldName]: Number(newValue),
                };
              }

              return {
                ...prev,
                [fieldName]: !prev[fieldName as COMMON_SETTING],
              };
            }

            return prev;
          });
        }
      }

      if (currenMenuItem === 1) {
        if (resourcesSettings?.[resourceNumber]?.errand_settings) {
          setResourcesSettings((prev) => {
            const result: ResourcesSettings = {
              ...prev,
              [resourceNumber]: {
                ...prev[resourceNumber],
                ['errand_settings']: {
                  ...prev[resourceNumber].errand_settings,
                  [fieldName]:
                    !prev[resourceNumber].errand_settings[
                      fieldName as ERRAND_SETTINGS
                    ],
                },
              },
            };

            if (
              !result[resourceNumber].errand_settings.use_global_provider &&
              !result[resourceNumber].errand_settings.use_cityflow &&
              !result[resourceNumber].errand_settings.use_here_sdk
            ) {
              result[resourceNumber].errand_settings[
                fieldName as ERRAND_SETTINGS
              ] = true;

              dispatch(
                setToast({
                  message: t`You have to choose one of three provider`,
                  severity: 'error',
                }),
              );
            }

            if (
              fieldName === ERRAND_SETTINGS.use_global_provider &&
              result[resourceNumber].errand_settings[fieldName]
            ) {
              result[resourceNumber].errand_settings.use_cityflow = false;
              result[resourceNumber].errand_settings.use_here_sdk = false;
            }
            if (
              fieldName === ERRAND_SETTINGS.use_cityflow &&
              result[resourceNumber].errand_settings[fieldName]
            ) {
              result[resourceNumber].errand_settings.use_global_provider =
                false;
              result[resourceNumber].errand_settings.use_here_sdk = false;
            }
            if (
              fieldName === ERRAND_SETTINGS.use_here_sdk &&
              result[resourceNumber].errand_settings[fieldName]
            ) {
              result[resourceNumber].errand_settings.use_cityflow = false;
              result[resourceNumber].errand_settings.use_global_provider =
                false;
            }

            return result;
          });
        }
      }

      if (currenMenuItem === 2) {
        if (resourcesSettings?.[resourceNumber]?.eta_settings) {
          setResourcesSettings((prev) => ({
            ...prev,
            [resourceNumber]: {
              ...prev[resourceNumber],
              ['eta_settings']: {
                ...prev[resourceNumber].eta_settings,
                [fieldName]:
                  !prev[resourceNumber].eta_settings[fieldName as ETA_SETTING],
              },
            },
          }));
        }
      }
    },
    [currenMenuItem, commonSettings, resourcesSettings],
  );

  const renderRowItem = useCallback(
    (rowItem: string) => {
      const currentRowStyle =
        rowItem !== 'namedColumn'
          ? styles.rowItem
          : { ...styles.rowItem, minWidth: '300px' };

      return (
        <Box
          key={uniqueId('ROW SETTINGS ITEM')}
          sx={rowItem === 'gap' ? { width: '16px' } : currentRowStyle}
        >
          {columnItemData.map((columnItem, columnIndex) => {
            const currentValue = getCurrentCheckBoxValue(rowItem, columnItem);
            const columItemStyle =
              currentResourceNumber === rowItem
                ? { ...styles.columnItem, backgroundColor: 'custom.blueLight3' }
                : styles.columnItem;

            if (rowItem === 'namedColumn') {
              return (
                <Grid
                  item
                  sx={styles.namedColumnItem}
                  key={uniqueId('FIELD NAME ITEM')}
                >
                  <Typography component="h6" sx={styles.columnLabel}>
                    {listOfFieldNames[columnIndex]}
                  </Typography>
                </Grid>
              );
            }

            if (rowItem === 'gap') {
              return (
                <Box
                  key={uniqueId('EMPTY COLUMN ITEM')}
                  sx={{ width: '16px' }}
                />
              );
            }

            if (
              columnIndex !== 0 &&
              columnItem === COMMON_SETTING.send_GPS_position_in_seconds
            ) {
              return (
                <Box key={uniqueId('COLUMN SETTINGS ITEM')} sx={columItemStyle}>
                  <SelectWithoutBorder
                    width="80px"
                    onChange={(val) => {
                      handleCheckBoxChange(val, rowItem, columnItem);
                    }}
                    value={String(currentValue)}
                    options={setGpsPositionOptions}
                    renderValue={(val) => val + ' s'}
                  />
                </Box>
              );
            }

            return (
              <Box key={uniqueId('COLUMN SETTINGS ITEM')} sx={columItemStyle}>
                {columnIndex === 0 ? (
                  <Typography component="h6">
                    {columnItem === 'common' ? ' ' : rowItem}
                  </Typography>
                ) : (
                  <Checkbox
                    type="green"
                    checked={!!currentValue}
                    onChange={(e) => {
                      handleCheckBoxChange(e.target.value, rowItem, columnItem);
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      );
    },
    [columnItemData, getCurrentCheckBoxValue, currentResourceNumber],
  );

  return (
    <GroupSettingProvider>
      <Box sx={styles.container}>
        <Typography sx={styles.title} component="h1">
          {t`Edit resources settings`}
        </Typography>

        <List sx={styles.list}>{menuList}</List>

        {currenMenuItem !== 3 ? (
          <Box sx={styles.subScreenContainer}>
            <Grid container columns={12} spacing={2} padding={2} paddingY={3}>
              <Box sx={styles.checkBoxesContainer}>
                {rowItemData.map(renderRowItem)}
              </Box>
            </Grid>
          </Box>
        ) : (
          <ResourceGroupsTab />
        )}

        {currenMenuItem !== 3 && (
          <Box sx={styles.bottomButtonContainer}>
            <Button
              onClick={() => navigate(-1)}
              sx={styles.cancelButton}
              variant="outlined"
            >
              {t`Cancel`}
            </Button>

            {currenMenuItem !== 0 && (
              <TablePagination
                component="div"
                count={totalResources}
                page={page}
                SelectProps={{
                  sx: { ...styles.paginationLabel, color: 'custom.black' },
                }}
                labelDisplayedRows={({ from, to, count }) => (
                  <Typography
                    component="span"
                    sx={styles.paginationLabel}
                  >{`${from}-${to} of ${count}`}</Typography>
                )}
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage={
                  <Typography component="span" sx={styles.paginationLabel}>
                    Resources per page
                  </Typography>
                }
                onPageChange={handleChangePage}
                rowsPerPage={size}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}

            <Button
              sx={styles.button}
              variant="contained"
              type="submit"
              onClick={handleSaveClick}
            >
              {t`Save`}
            </Button>
          </Box>
        )}
      </Box>
    </GroupSettingProvider>
  );
};
