import React, { Dispatch, memo, SetStateAction } from 'react';
import { Box, Typography } from '@mui/material';

import { ReportSelect } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/ReportSelect';
import { styles } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/TireReportTable/styles';

interface FuelReportTableProps {
  setCurrentReport: Dispatch<SetStateAction<string>>;
  currentReport: string;
}
export const FuelReportTable = memo((props: FuelReportTableProps) => {
  const { currentReport, setCurrentReport } = props;

  return (
    <Box sx={{ width: '100%', mt: '8px', flexGrow: 1, pl: '16px', pr: '16px' }}>
      <Box sx={styles.searchBlock}>
        <ReportSelect
          currentReport={currentReport}
          setCurrentReport={setCurrentReport}
        />
      </Box>

      <Typography variant="h1">This report sill in progress</Typography>
    </Box>
  );
});
