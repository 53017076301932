export const styles = {
  markerWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  resourceCircle: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    bgcolor: 'primary.main',
    margin: '3px',
  },
  resourceNumber: {
    position: 'absolute',
    left: 6,
    top: 30,
  },
  mapPoint: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    width: '24px',
    height: '24px',
    bgcolor: 'common.white',
    color: 'custom.black',
    borderWidth: '3px',
    borderColor: 'custom.black',
    borderStyle: 'solid',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.3)',
    },
  },

  priorityBlock: {
    position: 'absolute',
    top: -14,
    left: -14,
  },

  unreadMessage: {
    position: 'absolute',
    bottom: 22,
    left: 2,
  },

  bgUnreadMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    weight: '24px',
    borderRadius: '4px',
    bgcolor: 'common.white',
  },

  driverPositionGapBlock: {
    position: 'absolute',
    right: '16px',
    top: '20px',
    zIndex: 100,
    bgcolor: 'custom.white',
    borderRadius: '8px',
  },

  podEtaTitle: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: 'custom.black',
  },

  poper: {
    position: 'absolute',
    // right: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '150%',
    width: '100px',
    height: '50px',
    padding: '8px',
    borderRadius: '12px',
    borderWidth: '1px',
    borderColor: 'grey',
    bgcolor: 'common.white',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-8px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderLeft: '14px solid transparent',
      borderRight: '14px solid transparent',
      borderBottom: '14px solid white',
    },
  },
};
