import { TireType, WheelCharacteristic } from 'types/tiresReport';
import * as Yup from 'yup';

import { validationMessage } from './tireReportDetailsValidationMessages';

export type VehicleTypeForm = '1' | '2' | '3' | '4';

export interface MainFormValues {
  driverName: string;
  licensePlate: string;
  vehicleType: VehicleTypeForm;
  seasonType: TireType;
  frontLeft: WheelCharacteristic;
  frontRight: WheelCharacteristic;
  backLeft: WheelCharacteristic;
  backRight: WheelCharacteristic;
  backLeftOuter?: WheelCharacteristic;
  backRightOuter?: WheelCharacteristic;
  backLeft2ndRow?: WheelCharacteristic;
  backRight2ndRow?: WheelCharacteristic;
}

export const tireValidationSchema: Yup.SchemaOf<WheelCharacteristic> =
  Yup.object({
    pressure: Yup.number()
      .required(validationMessage.pressureRequire)
      .min(1, validationMessage.pressureMin)
      .max(10, validationMessage.pressureMax),
    depth: Yup.number()
      .required(validationMessage.depthRequire)
      .min(1, validationMessage.depthMin)
      .max(25, validationMessage.depthMax),
  });

// @ts-ignore
export const mainFormValidationSchema: Yup.SchemaOf<MainFormValues> =
  Yup.object().shape({
    // resourceNumber:
    //   Yup
    //     .string()
    //     .required(validationMessage.resourceRequire)
    //     .max(30, validationMessage.resourceMaxLength)
    //     .min(3, validationMessage.resourceMinLength)
    // ,
    driverName: Yup.string()
      .required(validationMessage.driverRequire)
      .max(50, validationMessage.driverMaxLength)
      .min(3, validationMessage.driverMinLength),
    licensePlate: Yup.string()
      .required(validationMessage.licenceRequire)
      .max(30, validationMessage.licenceMaxLength)
      .min(3, validationMessage.licenceMinLength),
    vehicleType: Yup.string().required(),
    seasonType: Yup.string().required(),
    frontLeft: tireValidationSchema,
    frontRight: tireValidationSchema,
    backRight: tireValidationSchema,
    backLeft: tireValidationSchema,
    // Conditional validation for back tires based on vehicleType
    backLeftOuter: Yup.object().when('vehicleType', {
      is: (type: VehicleTypeForm) => type === '2' || type === '4', // replace with your condition
      then: () => tireValidationSchema,
      otherwise: () => Yup.mixed().notRequired(),
    }),
    backRightOuter: Yup.object().when('vehicleType', {
      is: (type: VehicleTypeForm) => type === '2' || type === '4', // replace with your condition
      then: () => tireValidationSchema,
      otherwise: () => Yup.mixed().notRequired(),
    }),
    backLeft2ndRow: Yup.object().when('vehicleType', {
      is: (type: VehicleTypeForm) => type === '3' || type === '4', // replace with your condition
      then: () => tireValidationSchema,
      otherwise: () => Yup.mixed().notRequired(),
    }),
    backRight2ndRow: Yup.object().when('vehicleType', {
      is: (type: VehicleTypeForm) => type === '3' || type === '4', // replace with your condition
      then: () => tireValidationSchema,
      otherwise: () => Yup.mixed().notRequired(),
    }),
  });
