import { t } from 'ttag';
import {
  ErrandDataAgeControl,
  ErrandDataProofTypes,
  ShipmentStatuses,
} from 'types/api';
import { ChangeScreenErrandType } from 'types/changeScreenTypes';
import {
  getMaxLengthValidationError,
  getMaxValueValidationError,
  getMinValueValidationError,
  getRequiredFieldValidationError,
} from 'utils/textLabelUtils';
import * as yup from 'yup';

import {
  AdditionsDataTabFormValues,
  DeliveryDataTabFormValues,
  OrderDetailsTabFormValues,
  ParcelDataTabFormValues,
  PickUpDataTabFormValues,
} from './types';

export const orderDetailsTabFormValidationSchema: yup.SchemaOf<OrderDetailsTabFormValues> =
  yup.object({
    pickUpType: yup.mixed<ChangeScreenErrandType>().required(),
    deliveryType: yup.mixed<ChangeScreenErrandType>().required(),
    nameOfService: yup
      // .mixed<ErrandDataTitles>()
      .string()
      .max(50, t`Name of service should not be longer than 50 symbols`)
      .nullable()
      .required(getRequiredFieldValidationError('Name of service')),
    numberOfParcels: yup
      .number()
      .max(999, getMaxLengthValidationError('Number of parcel', 3))
      .required(getRequiredFieldValidationError('Number of parcel')),
    freightBill: yup
      .string()
      // .matches(/^[0-9]+$/, t`Freight bill should be numeric`)
      .max(50, getMaxLengthValidationError('Freight bill', 50)),
    bookingNumber: yup
      .string()
      // .matches(/^[0-9]+$/, t`Booking number should be numeric`)
      .max(50, t`Booking number should not be longer than 50 symbols`)
      .nullable()
      .required(getRequiredFieldValidationError('Booking number')),

    pickEarliest: yup
      .date()
      .typeError(t`You have entered incorrect date value`)
      // .max(yup.ref('pickLatest'), t`Earliest date should be before Latest`)
      .required(getRequiredFieldValidationError(t`Pick up earliest date`)),
    pickLatest: yup
      .date()
      .typeError(t`You have entered incorrect date value`)
      // .min(yup.ref('pickEarliest'), t`Latest date should be after Earliest`)
      // .max(
      //   yup.ref('deliveryEarliest'),
      //   t`Pick up date should be before delivery date`,
      // )
      .required(getRequiredFieldValidationError('Pick up latest date')),
    pickEta: yup
      .date()
      .nullable()
      .typeError(t`You have entered incorrect date or time value`),
    // .min(yup.ref('pickEarliest'), t`ETA date should be after Earliest`)
    // .max(yup.ref('pickLatest'), t`ETA date should be before Latest`),
    pickProofType: yup
      .mixed<ErrandDataProofTypes>()
      .nullable()
      .when('pickUpType', {
        is: 'terminal',
        otherwise: yup
          .mixed<ErrandDataProofTypes>()
          .nullable()
          .required(getRequiredFieldValidationError('Proof of pick up method')),
      })
      .oneOf(
        [...Object.values(ErrandDataProofTypes), null],
        getRequiredFieldValidationError('Proof of pick up method'),
      ),
    pickAgeControl: yup.mixed<ErrandDataAgeControl>(),
    pickOrderNumber: yup
      .number()
      .typeError(t`Order number should be of type 'number'`)
      // .max(9999, t`Order number should not be longer than 4 symbols`)
      .min(1, getMinValueValidationError(t`Order number`, 0))
      .lessThan(
        yup.ref('deliveryOrderNumber'),
        t`Pick order number should not be greater than delivery`,
      )
      .required(getRequiredFieldValidationError(t`Order number`))
      .integer(t`Order number should be of type integer`)
      .nullable(),

    deliveryEarliest: yup
      .date()
      .typeError(t`You have entered incorrect time value`)
      // .min(
      //   yup.ref('pickLatest'),
      //   t`Delivery date should be after the pick up date`,
      // )
      // .max(yup.ref('deliveryLatest'), t`Earliest date should be before Latest`)
      .required(getRequiredFieldValidationError('Delivery earliest date')),
    deliveryLatest: yup
      .date()
      .typeError(t`You have entered incorrect time value`)
      // .min(yup.ref('deliveryEarliest'), t`Latest date should be after Earliest`)
      .required(getRequiredFieldValidationError('Delivery latest date')),
    deliveryEta: yup
      .date()
      .nullable()
      .typeError(t`You have entered incorrect date or time value`),
    // .min(yup.ref('deliveryEarliest'), t`ETA date should be after Earliest`)
    // .max(yup.ref('deliveryLatest'), t`ETA date should be before Latest`),
    deliveryProofType: yup
      .mixed<ErrandDataProofTypes>()
      .oneOf(
        Object.values(ErrandDataProofTypes),
        getRequiredFieldValidationError('Proof of delivery method'),
      ),
    deliveryAgeControl: yup.mixed<ErrandDataAgeControl>(),
    deliveryOrderNumber: yup
      .number()
      .typeError(t`Order number should be of type 'number'`)
      // .max(9999, t`Order number should not be longer than 4 symbols`)
      .min(1, getMinValueValidationError(t`Order number`, 0))
      .moreThan(
        yup.ref('pickOrderNumber'),
        t`Delivery order number should not be less than pick`,
      )
      .positive(getMinValueValidationError(t`Order number`, 0))
      .required(getRequiredFieldValidationError('Order number'))
      .integer(t`Order number should be of type integer`)
      .nullable(),

    assignedResource: yup.string().required(t`Resource is required`),
    status: yup
      .mixed<ShipmentStatuses>()
      .oneOf(Object.values(ShipmentStatuses))
      .required(t`Status is a required field`),

    terminalBeLatest: yup
      .date()
      .nullable()
      .typeError(t`You have entered incorrect time value`),
    terminalLeaveLatest: yup
      .date()
      .nullable()
      .typeError(t`You have entered incorrect time value`),

    goodsType: yup
      .string()
      .nullable()
      .max(50, t`Goods type should not be longer than 50 symbols`),
    generalParcelType: yup
      .string()
      .nullable()
      .max(50, t`General parcel type should not be longer than 50 symbols`),
    goodsMarking: yup
      .string()
      .nullable()
      .max(50, t`Goods marking type should not be longer than 50 symbols`),
    palletLocation: yup
      .string()
      .nullable()
      .max(50, t`Pallet location should not be longer than 50 symbols`),
    packageLogo: yup.string().nullable(),
  });

export const pickUpDataTabFormValidationSchema: yup.SchemaOf<PickUpDataTabFormValues> =
  yup.object({
    pickUpTerminalId: yup.string().nullable(),
    pickSearch: yup.string().nullable().min(4),
    pickStreet: yup
      .string()
      .nullable()
      .min(3)
      .required(getRequiredFieldValidationError('Street name')),
    pickAddressId: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('Address ID')),
    pickCity: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('City name')),
    pickCountry: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('Country name')),
    pickLatitude: yup
      .number()
      .nullable()
      .required(getRequiredFieldValidationError('Latitude')),
    pickLongitude: yup
      .number()
      .nullable()
      .required(getRequiredFieldValidationError('Longitude')),
    pickPostalCode: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('Postal code')),
    pickState: yup.string().nullable(),
    // .required(getRequiredFieldValidationError('State name')),
    pickStreetNumber: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('Street number')),
    pickTerminalName: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Terminal name', 50)),
    // .required(getRequiredFieldValidationError('Terminal name')),

    pickCompanyName: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Company name', 50))
      .when('pickUpType', {
        is: 'business',
        then: yup.string().nullable(),
        // .required(getRequiredFieldValidationError('Company name')),
      }),
    pickNameOfSender: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Name of sender', 50))
      .when('pickUpType', {
        is: 'terminal',
        otherwise: yup
          .string()
          .nullable()
          // .required(getRequiredFieldValidationError('Name of sender'))
          .max(50, getMaxLengthValidationError('Name of sender', 50)),
      }),
    pickSurnameOfSender: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Surname of sender', 50))
      .when('pickUpType', {
        is: 'terminal',
        otherwise: yup
          .string()
          .nullable()
          // .required(getRequiredFieldValidationError('Surname of sender'))
          .max(50, getMaxLengthValidationError('Surname of sender', 50)),
      }),
    pickPhoneNumber: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Phone number', 20))
      .when('pickUpType', {
        is: 'terminal',
        otherwise: yup
          .string()
          .nullable()
          // .required(getRequiredFieldValidationError('Phone number'))
          .max(50, getMaxLengthValidationError('Phone number', 50)),
      }),

    pickCageCode: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Cage code', 50))
      .when('pickUpType', {
        is: 'terminal',
        then: yup.string().nullable(),
        // .required(getRequiredFieldValidationError('Cage code')),
      }),
    pickInstruction: yup
      .string()
      .nullable()
      .max(1000, getMaxLengthValidationError('Instruction', 1000)),
    pickEnterCode: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Gate code', 50)),
    pickIntercomCode: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Intercom code', 50)),
    pickFloorNumber: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Floor number', 50)),
    pickElevator: yup.boolean().nullable(),
    pickKnockOnTheDoor: yup.boolean().nullable(),
    pickPackagingReturn: yup.boolean().nullable(),
    pickUseDoorBell: yup.boolean().nullable(),
    pickQuietPickup: yup.boolean().nullable(),
  });

export const deliveryDataTabFormValidationSchema: yup.SchemaOf<DeliveryDataTabFormValues> =
  yup.object({
    deliveryTerminalId: yup.string().nullable(),
    deliverySearch: yup.string().nullable().min(4),
    deliveryStreet: yup
      .string()
      .nullable()
      .min(3)
      .required(getRequiredFieldValidationError('Street name')),
    deliveryAddressId: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('Address ID')),
    deliveryCity: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('City name')),
    deliveryCountry: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('Country name')),
    deliveryLatitude: yup
      .number()
      .nullable()
      .required(getRequiredFieldValidationError('Latitude')),
    deliveryLongitude: yup
      .number()
      .nullable()
      .required(getRequiredFieldValidationError('Longitude')),
    deliveryPostalCode: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('Postal code')),
    deliveryState: yup.string().nullable(),
    // .required(getRequiredFieldValidationError('State name')),
    deliveryStreetNumber: yup
      .string()
      .nullable()
      .required(getRequiredFieldValidationError('Street number')),
    deliveryTerminalName: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Terminal name', 50)),
    // .required(getRequiredFieldValidationError('Terminal name')),

    deliveryCompanyName: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Company name', 50))
      .when('deliveryType', {
        is: 'business',
        then: yup.string().nullable(),
        // .required(getRequiredFieldValidationError('Company name')),
      }),
    deliveryNameOfSender: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Name of sender', 50)),
    // .required(getRequiredFieldValidationError('Name of sender')),
    deliverySurnameOfSender: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Surname of sender', 50)),
    // .required(getRequiredFieldValidationError('Surname of sender')),
    deliveryPhoneNumber: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Phone number', 50)),
    // .required(getRequiredFieldValidationError('Phone number')),

    deliveryCageCode: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Cage code', 50))
      .when('deliveryType', {
        is: 'terminal',
        then: yup.string().nullable(),
        // .required(getRequiredFieldValidationError('Cage code')),
      }),
    deliveryInstruction: yup
      .string()
      .nullable()
      .max(1000, getMaxLengthValidationError('Instruction', 1000)),
    deliveryEnterCode: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Gate code', 50)),
    deliveryIntercomCode: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Intercom code', 50)),
    deliveryFloorNumber: yup
      .string()
      .nullable()
      .max(50, getMaxLengthValidationError('Floor number', 50)),
    deliveryElevator: yup.boolean().nullable(),
    deliveryKnockOnTheDoor: yup.boolean().nullable(),
    deliveryPackagingReturn: yup.boolean().nullable(),
    deliveryQuietDelivery: yup.boolean().nullable(),
    deliveryUseDoorBell: yup.boolean().nullable(),
  });

export const parcelDataTabFormValidationSchema: yup.SchemaOf<ParcelDataTabFormValues> =
  yup.object().shape({
    parcels: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        barcode: yup
          .string()
          .nullable()
          .max(50, t`Barcode must be at most 50 characters`),
        data: yup.object({
          parcel_type: yup
            .string()
            .max(
              50,
              t`General parcel type should not be longer than 50 symbols`,
            ),
          height: yup
            .number()
            .nullable()
            .min(0, getMinValueValidationError(t`Height`, 0))
            .max(1000, getMaxValueValidationError(t`Height`, 1000)),
          length: yup
            .number()
            .nullable()
            .min(0, getMinValueValidationError(t`Length`, 0))
            .max(1000, getMaxValueValidationError(t`Length`, 1000)),
          volume: yup
            .number()
            .nullable()
            .min(0, getMinValueValidationError(t`Volume`, 0))
            .max(1000, getMaxValueValidationError(t`Volume`, 1000)),
          weight: yup
            .number()
            .nullable()
            .min(0, getMinValueValidationError(t`Weight`, 0))
            .max(1000, getMaxValueValidationError(t`Weight`, 1000)),
          width: yup
            .number()
            .nullable()
            .min(0, getMinValueValidationError(t`Width`, 0))
            .max(1000, getMaxValueValidationError(t`Width`, 1000)),
        }),
      }),
    ),
  });

// @ts-ignore
export const additionsDataTabFormValidationSchema: yup.SchemaOf<AdditionsDataTabFormValues> =
  yup.object({
    adr: yup.boolean().nullable(),
    smallAdditionPackageCharge: yup.boolean().nullable(),
    bigAdditionPackageCharge: yup.boolean().nullable(),
    pickNewAddress: yup.boolean().nullable(),
    deliveryNewAddress: yup.boolean().nullable(),
    temperedTransport: yup.boolean().nullable(),
    onCallCourier: yup.boolean().nullable(),
    timeSlottedLorry: yup.boolean().nullable(),
    priority: yup.string(),

    pickWaitingTime: yup
      .number()
      .nullable()
      .min(0, getMinValueValidationError(t`Waiting pick time`, 0)),
    pickLoadingTime: yup
      .number()
      .nullable()
      .min(0, getMinValueValidationError(t`Loading time`, 0)),

    deliveryWaitingTime: yup
      .number()
      .nullable()
      .min(0, getMinValueValidationError(t`Waiting delivery time`, 0)),
    deliveryUnloadingTime: yup
      .number()
      .nullable()
      .min(0, getMinValueValidationError(t`Unloading time`, 0)),

    extraPalletSpace: yup
      .number()
      .nullable()
      .min(0, getMinValueValidationError(t`Extra pallet space`, 0)),
    flatbedMeter: yup
      .number()
      .nullable()
      .min(0, getMinValueValidationError(t`Flatbed meter`, 0)),

    ferryAdditionCost: yup
      .number()
      .nullable()
      .min(0, getMinValueValidationError(t`Installation`, 0)),
    installation: yup
      .number()
      .nullable()
      .min(0, getMinValueValidationError(t`Ferry addition cost`, 0)),
  });

export const changeScreenFormValidationSchema =
  parcelDataTabFormValidationSchema.concat(
    orderDetailsTabFormValidationSchema.concat(
      pickUpDataTabFormValidationSchema.concat(
        deliveryDataTabFormValidationSchema.concat(
          additionsDataTabFormValidationSchema,
        ),
      ),
    ),
  );
