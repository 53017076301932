import React, { FC, memo, useCallback, useMemo } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ReactComponent as AndroidIcon } from 'assets/icons/android_icon.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car_without_color.svg';
import { format } from 'date-fns';
import { useResourcesReportQuery } from 'store/api/adminApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  updateResourceOrderBy,
  updateResourcePage,
  updateResourceSize,
} from 'store/reducers/deviceInfoReportSlice';
import {
  getResourceNumberFilter,
  getResourceOrder,
  getResourceOrderBy,
  getResourcePage,
  getResourceSize,
} from 'store/selectors/deviseInfoReports';
import { t } from 'ttag';
import { ResourceReportOrdering } from 'types/deviceInfoReport';
import { SortOrder } from 'utils/tableUtils';
import { createUniqueId } from 'utils/uniqIdUtils';

import { CurrentResource } from 'components/screens/AdminHomeScreen/components/DeviceInfoReport/DeviceInfoReport';
import { DeviceInfoReportResourceFilterButton } from 'components/ui/DeviceInfoReportResourceFilterButton';

import {
  deviceResourceHeadCells,
  DeviceResourceHeadCellTitle,
  strWithoutMinus,
} from './deviceResourceTableUtils';
import { styles } from './styles';

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: DeviceResourceHeadCellTitle | ResourceReportOrdering,
  ) => void;
  order: SortOrder | null;
  orderBy: string | null;
  rowCount: number;
}

const EnhancedTableHead = memo((props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: DeviceResourceHeadCellTitle | ResourceReportOrdering) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const getBorderStyle = useCallback((isBorder: boolean): SxProps => {
    return isBorder ? styles.rightBorder : {};
  }, []);

  return (
    <TableHead>
      <TableRow>
        {deviceResourceHeadCells.map((headCell, idx) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id && order ? order : false}
            sx={{
              bgcolor: 'custom.grey3',
              ...getBorderStyle(idx < deviceResourceHeadCells.length - 1),
            }}
          >
            <TableSortLabel
              disabled={!headCell.isSorted}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id && order ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.id === 'android_version' ? (
                <Box
                  ml={headCell.id && !headCell.numeric ? '20px' : '0px'}
                  pt="8px"
                  sx={{ fontWeight: '700 !important' }}
                >
                  <AndroidIcon />
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  ml={
                    orderBy !== headCell.id && !headCell.numeric
                      ? '16px'
                      : '0px'
                  }
                  sx={{ fontWeight: '700 !important' }}
                >
                  {headCell.label}
                </Typography>
              )}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

interface FeedbacksTableProps {
  setCurrentResource: React.Dispatch<
    React.SetStateAction<CurrentResource | null>
  >;
}

export const DeviceResourceTable: FC<FeedbacksTableProps> = React.memo(
  ({ setCurrentResource }) => {
    const dispatch = useAppDispatch();
    const orderBy = useAppSelector(getResourceOrderBy);
    const order = useAppSelector(getResourceOrder);
    const page = useAppSelector(getResourcePage);
    const size = useAppSelector(getResourceSize);
    const resourceFilter = useAppSelector(getResourceNumberFilter);

    const { data } = useResourcesReportQuery({
      order_by: orderBy,
      page,
      size,
      resource_number: resourceFilter,
    });

    const rows = useMemo(() => {
      if (!data) {
        return [];
      }

      return data?.items?.length ? data.items : [];
    }, [data]);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: DeviceResourceHeadCellTitle | ResourceReportOrdering,
    ) => {
      const isAsc = orderBy === property && order === 'asc';

      dispatch(
        updateResourceOrderBy(
          (isAsc ? `-${property}` : property) as ResourceReportOrdering,
        ),
      );
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(updateResourcePage(newPage + 1));
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      dispatch(updateResourceSize(parseInt(event.target.value, 10)));
      dispatch(updateResourcePage(1));
    };

    return (
      <Box
        sx={{ width: '100%', mt: '8px', flexGrow: 1, pl: '16px', pr: '16px' }}
      >
        <Box sx={styles.searchBlock}>
          <DeviceInfoReportResourceFilterButton />
          <Button
            sx={styles.button}
            variant="outlined"
            endIcon={<DownloadIcon color="primary" />}
          >
            {t`Export`}
          </Button>
        </Box>
        <Paper sx={styles.tablePaper}>
          <TableContainer sx={{ maxHeight: '550px' }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={strWithoutMinus(orderBy)}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map((row, index) => {
                  // const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() =>
                        setCurrentResource({
                          resourceId: row.id,
                          resourceNumber: row.resource_number,
                        })
                      }
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      // tabIndex={-1}
                      key={createUniqueId()}
                      // selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        align="left"
                        variant="body"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            textTransform: 'none',
                            width: '100px',
                          } as SxProps
                        }
                      >
                        <Box sx={styles.deviceIdBlock}>
                          <CarIcon />
                          <Typography
                            variant="body1"
                            ml="6px"
                            sx={{ lineHeight: '20px' }}
                          >
                            {row.resource_number}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        align="center"
                        variant="body"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            textTransform: 'none',
                            width: '150px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {row.login_time
                            ? format(
                                new Date(row.login_time as string),
                                'yyyy-MM-dd HH:mm',
                              )
                            : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '150px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {row.logout_time
                            ? format(
                                new Date(row.logout_time as string),
                                'yyyy-MM-dd HH:mm',
                              )
                            : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '200px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px', textTransform: 'none' }}
                        >
                          {row.device_info}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '50px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {row.android_version}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && ( */}
                {/*   <TableRow */}
                {/*     style={{ */}
                {/*       height: 33 * emptyRows, */}
                {/*     }} */}
                {/*   > */}
                {/*     <TableCell colSpan={4} /> */}
                {/*   </TableRow> */}
                {/* )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.total || 0}
            rowsPerPage={size}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={styles.topBorder}
          />
        </Paper>
      </Box>
    );
  },
);
