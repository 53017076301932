import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { t } from 'ttag';

import { styles } from './styles';

const options = [
  {
    value: '1',
    label: t`Tire & Tread`,
  },
  {
    value: '2',
    label: t`Fuel report`,
  },
];

interface ReportSelectProps {
  currentReport: string;
  setCurrentReport: Dispatch<SetStateAction<string>>;
}
export const ReportSelect = memo((props: ReportSelectProps) => {
  const { currentReport, setCurrentReport } = props;
  const handleChange = useCallback((event: SelectChangeEvent) => {
    setCurrentReport(event.target.value);
  }, []);

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={currentReport}
      // label="Age"
      onChange={handleChange}
      sx={styles.select}
    >
      {options.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
});
