import React, { FC, memo, useCallback, useMemo } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ReactComponent as AndroidIcon } from 'assets/icons/android_icon.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car_without_color.svg';
import { format } from 'date-fns';
import { useAllFeedbacksQuery } from 'store/api/adminApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  updateFeedbackOrderBy,
  updateFeedbackPage,
  updateFeedbackSize,
} from 'store/reducers/feedbackSlice';
import {
  getFeedbackDevicesFilters,
  getFeedbackDriversFilterss,
  getfeedbackEndDate,
  getFeedbackOrder,
  getFeedbackOrderBy,
  getFeedbackPage,
  getFeedbackResourcesFilters,
  getFeedbackSize,
  getFeedbackStartDate,
  getFeedbackStatusesFilters,
  getFeedbackVersionsFilters,
} from 'store/selectors/feedback';
import { t } from 'ttag';
import { SortOrder } from 'utils/tableUtils';
import { createUniqueId } from 'utils/uniqIdUtils';

import { FeedbackFilterButton } from 'components/ui/FeedbackFilterButton';

import {
  feedbackHeadCells,
  FeedbackHeadCellTitle,
  getStatusColor,
  strWithoutMinus,
} from './feedbacksTableUtils';
import { styles } from './styles';

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: FeedbackHeadCellTitle,
  ) => void;
  order: SortOrder;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = memo((props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: FeedbackHeadCellTitle) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const getBorderStyle = useCallback((isBorder: boolean): SxProps => {
    return isBorder ? styles.rightBorder : {};
  }, []);

  return (
    <TableHead>
      <TableRow>
        {feedbackHeadCells.map((headCell, idx) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              bgcolor: 'custom.grey3',
              ...getBorderStyle(idx < feedbackHeadCells.length - 1),
            }}
          >
            <TableSortLabel
              disabled={!headCell.isSorted}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.id === 'android_version' ? (
                <Box
                  ml={headCell.id && !headCell.numeric ? '20px' : '0px'}
                  pt="8px"
                  sx={{ fontWeight: '700 !important' }}
                >
                  <AndroidIcon />
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  ml={
                    orderBy !== headCell.id && !headCell.numeric
                      ? '16px'
                      : '0px'
                  }
                  sx={{ fontWeight: '700 !important' }}
                >
                  {headCell.label}
                </Typography>
              )}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

interface FeedbacksTableProps {
  setCurrentFeedbackId: React.Dispatch<React.SetStateAction<number | null>>;
}
export const FeedbacksTable: FC<FeedbacksTableProps> = React.memo(
  ({ setCurrentFeedbackId }) => {
    const dispatch = useAppDispatch();
    const orderBy = useAppSelector(getFeedbackOrderBy);
    const order = useAppSelector(getFeedbackOrder);
    const page = useAppSelector(getFeedbackPage);
    const size = useAppSelector(getFeedbackSize);
    const resourceFilter = useAppSelector(getFeedbackResourcesFilters);
    const driverFilter = useAppSelector(getFeedbackDriversFilterss);
    const deviceFilter = useAppSelector(getFeedbackDevicesFilters);
    const androidFilter = useAppSelector(getFeedbackVersionsFilters);
    const statusFilter = useAppSelector(getFeedbackStatusesFilters);
    const startDate = useAppSelector(getFeedbackStartDate);
    const endDate = useAppSelector(getfeedbackEndDate);

    const { data } = useAllFeedbacksQuery({
      order_by: orderBy,
      page,
      size,
      resourceFilter,
      driverFilter,
      deviceFilter,
      androidFilter,
      statusFilter,
      startDate,
      endDate,
    });

    const rows = useMemo(() => {
      if (!data) {
        return [];
      }

      return data?.items?.length ? data.items : [];
    }, [data]);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: FeedbackHeadCellTitle,
    ) => {
      // @ts-ignore
      const isAsc = orderBy === property && order === 'asc';
      // @ts-ignore
      dispatch(updateFeedbackOrderBy(isAsc ? `-${property}` : property));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(updateFeedbackPage(newPage + 1));
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      dispatch(updateFeedbackSize(parseInt(event.target.value, 10)));
      dispatch(updateFeedbackPage(1));
    };

    // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setDense(event.target.checked);
    // };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = useMemo(() => {
    //   return page > 0
    //     ? Math.max(0, (1 + page) * size - rows.length)
    //     : 0;
    // }, [rows.length, page, size]);

    // const visibleRows = React.useMemo(
    //   () =>
    //     stableSort(rows, getComparator(order, orderBy)).slice(
    //       page * size,
    //       page * rowsPerPage + rowsPerPage,
    //     ),
    //   [order, orderBy, page, rowsPerPage, rows],
    // );

    return (
      <Box
        sx={{ width: '100%', mt: '8px', flexGrow: 1, pl: '16px', pr: '16px' }}
      >
        <Box sx={styles.searchBlock}>
          <FeedbackFilterButton />
          <Button
            sx={styles.button}
            variant="outlined"
            endIcon={<DownloadIcon color="primary" />}
          >
            {t`Export`}
          </Button>
        </Box>
        <Paper sx={styles.tablePaper}>
          <TableContainer sx={{ maxHeight: '500px' }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={strWithoutMinus(orderBy)}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map((row, index) => {
                  // const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() => setCurrentFeedbackId(row.id)}
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      // tabIndex={-1}
                      key={createUniqueId()}
                      // selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        align="left"
                        variant="body"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            textTransform: 'none',
                            width: '100px',
                          } as SxProps
                        }
                      >
                        <Box sx={styles.deviceIdBlock}>
                          <CarIcon />
                          <Typography
                            variant="body1"
                            ml="6px"
                            sx={{ lineHeight: '20px' }}
                          >
                            {row.resource_number}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        align="left"
                        variant="body"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            textTransform: 'none',
                            width: '100px',
                          } as SxProps
                        }
                      >
                        <Box sx={styles.deviceIdBlock}>
                          <Typography
                            variant="body1"
                            ml="6px"
                            sx={{ lineHeight: '20px' }}
                          >
                            {row.driver_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '150px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {format(
                            new Date(row.created_at as string),
                            'yyyy-MM-dd HH:mm',
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '200px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px', textTransform: 'none' }}
                        >
                          {row.device_info}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '50px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {row.android_version}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{
                            lineHeight: '20px',
                            textTransform: 'none',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {row.comment}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '100px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{
                            ...styles.statusText,
                            color: getStatusColor(row.status),
                          }}
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && ( */}
                {/*   <TableRow */}
                {/*     style={{ */}
                {/*       height: 33 * emptyRows, */}
                {/*     }} */}
                {/*   > */}
                {/*     <TableCell colSpan={4} /> */}
                {/*   </TableRow> */}
                {/* )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.total || 0}
            rowsPerPage={size}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={styles.topBorder}
          />
        </Paper>
      </Box>
    );
  },
);
