import { BarcodesScan } from 'types/terminalScanChart';

export const getWeightBarcodesObject = (barcodes?: BarcodesScan) => {
  if (!barcodes || typeof barcodes !== 'object') {
    return 0;
  }

  const barcodesValues = Object.values(barcodes);

  if (barcodesValues.every((barcode) => barcode)) {
    return 2;
  }

  if (barcodesValues.some((barcode) => barcode)) {
    return 1;
  }

  return 0;
};
