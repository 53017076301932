import { FieldProps, getIn } from 'formik';

import { Select } from 'components/ui/Select';

export const FieldSelect = ({
  field,
  form: { errors },
  ...props
}: FieldProps) => {
  const errorMessage = getIn(errors, field.name);

  return (
    <Select
      {...field}
      {...props}
      // @ts-ignore
      value={field?.value || ''}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};
