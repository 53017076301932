import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  iconContainer: {
    position: 'relative',
    color: 'custom.greyLight',
    zIndex: 10,
    border: '3px solid',
    borderColor: 'transparent',
  },
  priorityBlock: {
    position: 'absolute',
    right: -10,
    top: -15,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    padding: '5px',

    borderRadius: '50%',
    bgcolor: 'custom.greyLight',
  },

  collapsibleIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    padding: '5px',

    borderRadius: '50%',
    border: '1px solid',
    borderColor: 'common.white',
    bgcolor: 'custom.greyLight',
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'common.white',

    '&:before': {
      position: 'absolute',
      zIndex: -1,
      bottom: '-10px',
      width: '30px',
      height: '30px',

      content: '""',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: 'common.white',
      bgcolor: 'inherit',
    },

    '&:after': {
      position: 'absolute',
      zIndex: -1,
      top: '-10px',
      width: '30px',
      height: '30px',

      content: '""',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: 'common.white',
      bgcolor: 'inherit',
    },
  },

  iconTextContent: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'common.white',
  },

  iconSvgContent: {
    fontSize: '20px',
    color: 'common.white',
  },

  leftTriangle: {
    position: 'absolute',
    left: '-9px',
    top: '12px',
  },

  rightTriangle: {
    position: 'absolute',
    right: '-26px',
    top: '12px',
  },
};
