export const COLORS = {
  black: '#212121',
  white: '#ffffff',
  orangePrimary: '#ff8200',
  orangePrimaryLight: '#fff0de',
  orangeSecondary: '#ffa300',
  orangeLight: '#FFF0E0',
  graphite: '#666666',
  grey: '#BFBFBF',
  grey2: '#E8E8E8',
  grey3: '#F5F5F5',
  greyLight: '#E0E0E0',
  greyLight2: '#E3E3E3',
  greyLight3: '#D9D9D9',
  greyBlue: '#EBEFF2',
  greyLightBlue: '#F5F7F8',
  greyLightBlue2: '#E4EDFC',
  greyLightBlue3: '#C6D8F9',
  greyBackground: '#f7f8f8',
  blue: '#018CF1',
  blue2: '#017DD8',
  blue3: '#016CBB',
  blueLight: '#e5edf8',
  blueLight2: '#1973E7',
  blueLight3: 'rgba(161, 194, 247, 0.2)',
  darkBlue: '#003F6C',
  green: '#42a146',
  green2: 'rgba(66, 161, 70, 0.5)',
  green3: 'rgba(66, 161, 70, 0.1)',
  green4: '#C6E3C7',
  lightGreen: '#1FD655',
  yellow: '#ffcd00',
  red: '#DA291C',
  red2: '#EA352B',
  red3: '#F4BFBB',
  gold: '#AE8906',
  brown: '#B16102',
  main15: 'rgba(255, 130, 0, 0.15)',
  purple: '#8A05AF',
};
