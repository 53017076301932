import { memo, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export type OptionsType = {
  value: string;
  label: string;
};

interface SelectWithoutBorderProps {
  width?: string;
  options: OptionsType[];
  value: string;
  onChange: (value: string) => void;
  renderValue?: (val: string) => string;
}
export const SelectWithoutBorder = memo((props: SelectWithoutBorderProps) => {
  const { width = '100px', options, value, onChange, renderValue } = props;

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <FormControl
    // sx={{ m: 1, minWidth: 120 }} size="small"
    >
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        // label="Age"
        onChange={handleChange}
        sx={{
          width,
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        renderValue={renderValue}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
