import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TireReportOrdering } from 'types/tiresReport';
import { SortOrder } from 'utils/tableUtils';

interface TireReportState {
  orderBy: TireReportOrdering;
  order: SortOrder;
  page: number;
  size: number;
  resourceFilters: string[];
  driverFilters: string[];
  licenceFilters: string[];
}

const initialState: TireReportState = {
  orderBy: '-created_at',
  order: 'desc',
  page: 1,
  size: 10,
  resourceFilters: [],
  driverFilters: [],
  licenceFilters: [],
};

export const tireReportSlice = createSlice({
  name: 'tireReport',
  initialState,
  reducers: {
    updateTireOrderBy: (state, action: PayloadAction<TireReportOrdering>) => {
      state.orderBy = action.payload;
      state.order = action.payload.startsWith('-') ? 'desc' : 'asc';
    },
    updateTirePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateTireSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    updateTireDriverFilters: (state, action: PayloadAction<string[]>) => {
      state.driverFilters = action.payload;
    },
    updateTireResourceFilters: (state, action: PayloadAction<string[]>) => {
      state.resourceFilters = action.payload;
    },
    updateTireLicenceFilters: (state, action: PayloadAction<string[]>) => {
      state.licenceFilters = action.payload;
    },

    removeTireFilters: (state) => {
      state.driverFilters = [];
      state.resourceFilters = [];
      state.licenceFilters = [];
    },
  },
});

export const {
  updateTireDriverFilters,
  updateTireLicenceFilters,
  updateTireOrderBy,
  updateTirePage,
  updateTireResourceFilters,
  updateTireSize,
  removeTireFilters,
} = tireReportSlice.actions;

export const tireReportReducer = tireReportSlice.reducer;
