import { FC, useMemo, useState } from 'react';

import { FuelReportTable } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/FuelReportTable';

import { TireReportDetail } from './components/TireReportDetail';
import { TireReportTable } from './components/TireReportTable';

export const CompanyReports: FC = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [currentReport, setCurrentReport] = useState<string>('1');

  const tireReportContent = useMemo(() => {
    if (!selectedId) {
      return (
        <TireReportTable
          setCurrentReport={setCurrentReport}
          currentReport={currentReport}
          setCurrentFeedbackId={setSelectedId}
        />
      );
    }

    return (
      <TireReportDetail selectedId={selectedId} setSelectedId={setSelectedId} />
    );
  }, [selectedId, setSelectedId, currentReport, setCurrentReport]);

  const fuelReportContent = useMemo(() => {
    if (!selectedId) {
      return (
        <FuelReportTable
          setCurrentReport={setCurrentReport}
          currentReport={currentReport}
        />
      );
    }

    return null;
  }, [currentReport, setCurrentReport]);

  const content = useMemo(() => {
    if (currentReport === '1') {
      return tireReportContent;
    }

    if (currentReport === '2') {
      return fuelReportContent;
    }
    return null;
  }, [currentReport, tireReportContent]);

  return content;
};
