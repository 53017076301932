import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { styles } from './styles';
interface CopyOrderToNewPopupProps {
  onCopyPress: () => void;
  content: string;
}

function CopyOrderToNewPopup({
  onCopyPress,
  content,
}: CopyOrderToNewPopupProps) {
  return (
    <Box sx={styles.copyContainer} boxShadow={3} component={Grid}>
      <Box sx={styles.itemContainer} onClick={onCopyPress}>
        <Typography fontSize="16px">{content}</Typography>
      </Box>
    </Box>
  );
}

export default React.memo(CopyOrderToNewPopup);
