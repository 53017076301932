import React, { memo, useCallback } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { t } from 'ttag';

import { styles } from './styles';

interface TireReportHeaderProps {
  setCurrentFeedbackId: React.Dispatch<React.SetStateAction<number | null>>;
  licence: string;
  resourceNumber: string;
}
export const TireReportDetailHeader = memo((props: TireReportHeaderProps) => {
  const { setCurrentFeedbackId, licence, resourceNumber } = props;

  const handleBackPress = useCallback(() => {
    setCurrentFeedbackId(null);
  }, [setCurrentFeedbackId]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={styles.container}
    >
      <Box sx={styles.buttonBlock}>
        <IconButton onClick={handleBackPress}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={styles.title}>
          {licence} / {resourceNumber} ({t`TIRE & TREAD`})
        </Typography>
      </Box>
    </Stack>
  );
});
