import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { adminApiSlice } from 'store/api/adminApiSlice';

import { apiSlice } from './api/apiSlice';
import { chatApiSlice } from './api/chatApiSlice';
import { authReducer } from './reducers/authSlice';
import { chatReducer } from './reducers/chatSlice';
import { companyReducer } from './reducers/companySlice';
import { deviceInfoReportReducer } from './reducers/deviceInfoReportSlice';
import { driverReducer } from './reducers/driverSlice';
import { feedbackReducer } from './reducers/feedbackSlice';
import { mapSettingsReducer } from './reducers/mapSettingsSlice';
import { orderReducer } from './reducers/orderSlice';
import { resourceReducer } from './reducers/resourceSlice';
import { settingsReducer } from './reducers/settingsSlice';
import { tireReportReducer } from './reducers/tireReportSlice';
import { workshiftReducer } from './reducers/workshiftSlice';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [chatApiSlice.reducerPath]: chatApiSlice.reducer,
  [adminApiSlice.reducerPath]: adminApiSlice.reducer,
  auth: authReducer,
  resource: resourceReducer,
  driver: driverReducer,
  chat: chatReducer,
  order: orderReducer,
  workshift: workshiftReducer,
  settings: settingsReducer,
  company: companyReducer,
  mapSettings: mapSettingsReducer,
  feedback: feedbackReducer,
  deviceInfoReport: deviceInfoReportReducer,
  tireReport: tireReportReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      chatApiSlice.middleware,
      adminApiSlice.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
