import { createTypedSelector } from 'store/utils';

export const getTireOrderBy = createTypedSelector(
  (state) => state.tireReport.orderBy,
);

export const getTireOrder = createTypedSelector(
  (state) => state.tireReport.order,
);

export const getTirePage = createTypedSelector(
  (state) => state.tireReport.page,
);

export const getTireSize = createTypedSelector(
  (state) => state.tireReport.size,
);

export const getTireResourceFilter = createTypedSelector(
  (state) => state.tireReport.resourceFilters,
);

export const getTireDriverFilter = createTypedSelector(
  (state) => state.tireReport.driverFilters,
);
export const getTireLicenceFilter = createTypedSelector(
  (state) => state.tireReport.licenceFilters,
);
