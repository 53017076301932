import { t } from 'ttag';

export const validationMessage = {
  resourceRequire: t`Resource number is required`,
  resourceMaxLength: t`Max length is 30 symbols`,
  resourceMinLength: t`Min length is 3 symbols`,
  driverMaxLength: t`Max length is 50 symbols`,
  driverMinLength: t`Min length is 3 symbols`,
  driverRequire: t`Driver name is required`,
  licenceMaxLength: t`Max length is 30 symbols`,
  licenceMinLength: t`Min length is 3 symbols`,
  licenceRequire: t`License plate is required`,
  pressureMin: t`Min pressure is 1 Bar`,
  pressureMax: t`Max pressure is 10 Bar`,
  pressureRequire: t`Pressure is required`,
  depthMin: t`Min depth is 1 mm`,
  depthMax: t`Max depth is 25 mm`,
  depthRequire: t`Depth is required`,
};
