import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MapBox, {
  Layer,
  LayerProps,
  MapLayerMouseEvent,
  MapRef,
  Marker,
  Popup,
  Source,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { Box, Grid, Typography } from '@mui/material';
import arrowImage from 'assets/images/resourceIconArrow.png';
import {
  MAP_COMPONENT_TYPE,
  MAP_POINT_TYPE,
  UNASSIGNED_TASKS,
} from 'constants/common';
import { useHasChanged } from 'hooks/useHasChanged';
import { useMapDriverHistoricalPathData } from 'hooks/useMapDriverHistoricalPathData';
import {
  MapPoint,
  PointStatus,
  useResourceRouteData,
} from 'hooks/useResourceRouteData';
import { throttle } from 'lodash';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateLastMapCenter } from 'store/reducers/mapSettingsSlice';
import { setSelectedOrderId } from 'store/reducers/orderSlice';
import { updateShowDetailSection } from 'store/reducers/settingsSlice';
import {
  getDriverHistoricalPathSelector,
  getLastMapCenterSelector,
  getShowUnreadInMapSelector,
} from 'store/selectors/mapSettings';
import { getSelectedOrderIdSelector } from 'store/selectors/order';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import {
  getDetailsUnderOrdersSelector,
  getShowDetailSectionSelector,
} from 'store/selectors/settings';
import { DriverHistoricalPath } from 'types/api';
import { Coordinates, Resource } from 'types/resource';
import LngLatBounds from 'utils/lngLtdBounds';

import { HistoricalPointInfo } from 'components/common/Map/components/HistoricalPointInfo';
import { MapPointInfo } from 'components/common/Map/components/MapPointInfo';
import { MapUnreadMessagesByPriority } from 'components/ui/MapUnreadMessagesByPriority';
import { PriorityToken } from 'components/ui/PriorityToken';
import { TurnShirtGapTimer } from 'components/ui/TurnShirtGapTimer';

import { MapTooltip } from './components/MapTooltip';
import { styles } from './styles';

interface MapComponentProps {
  type?: MAP_COMPONENT_TYPE;
  resources?: Resource[];
}

// eslint-disable-next-line react/display-name
export const Map: FC<MapComponentProps> = React.memo(
  (props: MapComponentProps) => {
    const { type = MAP_COMPONENT_TYPE.resources, resources = [] } = props;
    const mapRef = useRef<MapRef>(null);
    const timeOutInitial = useRef<NodeJS.Timeout>();
    const timeOutByDataChange = useRef<NodeJS.Timeout>();
    const [showPopup, setShowPopup] = useState(false);
    const [coords, setCoords] = useState<Coordinates | []>([]);
    const [pointClick, setPointClick] = useState<MapPoint | null>(null);
    const [historicalClick, setHistoricalClick] =
      useState<DriverHistoricalPath | null>(null);
    const [resourceClick, setResourceClick] = useState(false);
    const [cursorStyle, setCursorStyle] = useState<string | undefined>(
      undefined,
    );
    const dispatch = useAppDispatch();
    const showDetailSection = useAppSelector(getShowDetailSectionSelector);
    const driverHistoricalPath = useAppSelector(
      getDriverHistoricalPathSelector,
    );
    const showUnreadInMap = useAppSelector(getShowUnreadInMapSelector);

    const [localSelectedResource, setSelectedResourceId] = useState<
      string | undefined
    >();
    const { historicalPathPoints } = useMapDriverHistoricalPathData();
    const { mapPoints, linePoints } = useResourceRouteData();
    const hasMapPointsChanged = useHasChanged(mapPoints);
    const hasResourcesChanged = useHasChanged(resources);
    const currentOrder = useAppSelector(getSelectedOrderIdSelector);
    const detailsUnderOrdersStatus = useAppSelector(
      getDetailsUnderOrdersSelector,
    );
    const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
    const selectedOrderId = useAppSelector(getSelectedOrderIdSelector);
    const localUpdatedResourceId = useRef(selectedResourceId);
    const localUpdatedOrderId = useRef(selectedOrderId);
    const lastMapCenter = useAppSelector(getLastMapCenterSelector);

    useEffect(() => {
      if (selectedResourceId !== localUpdatedResourceId.current) {
        localUpdatedResourceId.current = selectedResourceId;
        setShowPopup(false);
      }
    }, [selectedResourceId]);

    useEffect(() => {
      if (selectedOrderId !== localUpdatedOrderId.current) {
        localUpdatedOrderId.current = selectedOrderId;
        setShowPopup(false);
      }
    }, [selectedOrderId]);

    const coordinates = useMemo(
      () => resources.map((resource) => resource.coordinates),
      [resources],
    );

    const handleMapMove = useCallback((e: ViewStateChangeEvent) => {
      dispatch(
        updateLastMapCenter({
          longitude: e.viewState.longitude,
          latitude: e.viewState.latitude,
        }),
      );
    }, []);

    const handleMapPointClick = useCallback(
      (point: MapPoint) => {
        if (showPopup) {
          setShowPopup(false);
        }
        if (!showDetailSection) {
          dispatch(updateShowDetailSection(true));
        }

        if (point?.orderData?.length && point.orderData.length > 1) {
          setResourceClick(false);
          setHistoricalClick(null);
          setPointClick(point);
          setCoords(point.coords);
          setShowPopup(true);

          return;
        }

        dispatch(setSelectedOrderId(point.id));
      },
      [dispatch, showDetailSection, showPopup],
    );

    const markers = useMemo(() => {
      if (type === MAP_COMPONENT_TYPE.resources) {
        if (!resources.length) {
          return null;
        }

        return resources.map((resource, idx) => (
          <Marker
            key={`Vehicle} ${idx}`}
            onClick={() => {
              if (resource?.coordinates) {
                setCoords(resource.coordinates);
              }
              setPointClick(null);
              setHistoricalClick(null);
              setResourceClick(true);
              setSelectedResourceId(resource.id);
              setShowPopup(true);
            }}
            longitude={(resource?.coordinates && resource.coordinates[0]) || 0}
            latitude={(resource?.coordinates && resource.coordinates[1]) || 0}
          >
            <Grid
              onClick={(e) => {
                e.stopPropagation();
              }}
              sx={{
                ...styles.markerWrapper,
                transform: `rotate(${resource?.details?.dir || 0}deg)`,
              }}
            >
              <img src={arrowImage} alt="Source Arrow" width={20} />
              <Box sx={styles.resourceCircle} />
              <Typography
                variant="caption"
                color="common.white"
                fontSize={12}
                fontWeight={600}
                letterSpacing={1.1}
                textAlign="center"
                sx={{
                  ...styles.resourceNumber,
                  transform: `rotate(-${resource?.details?.dir || 0}deg)`,
                }}
              >
                {resource.number}
              </Typography>
            </Grid>
          </Marker>
        ));
      } else {
        return mapPoints.map((point, idx) => {
          if (!point?.coords) {
            return null;
          }

          if (point.type === MAP_POINT_TYPE.resource) {
            return (
              <Marker
                key={`Vehicle In Map Route} ${idx}`}
                onClick={() => {
                  if (point?.coords) {
                    setCoords(point.coords);
                  }
                  setPointClick(null);
                  setHistoricalClick(null);
                  setResourceClick(true);
                  setSelectedResourceId(selectedResourceId || undefined);
                  setShowPopup(true);
                }}
                longitude={point.coords[0]}
                latitude={point.coords[1]}
              >
                <Grid
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    ...styles.markerWrapper,
                    transform: `rotate(${point?.details?.dir || 0}deg)`,
                  }}
                >
                  <img src={arrowImage} alt="Source Arrow" width={20} />
                  <Box sx={styles.resourceCircle} />
                  <Typography
                    variant="caption"
                    color="common.white"
                    fontSize={12}
                    fontWeight={600}
                    letterSpacing={1.1}
                    textAlign="center"
                    sx={{
                      ...styles.resourceNumber,
                      transform: `rotate(-${point?.details?.dir || 0}deg)`,
                    }}
                  >
                    {point.resourceNumber}
                  </Typography>
                </Grid>
              </Marker>
            );
          }

          if (point?.status) {
            const pointStyle = (status: PointStatus) => {
              switch (status) {
                case 'fail':
                  return {
                    ...styles.mapPoint,
                    bgcolor: 'custom.red',
                    color: 'custom.white',
                    borderRadius: '0px',
                  };
                case 'done':
                default:
                  return {
                    ...styles.mapPoint,
                    bgcolor: 'custom.green',
                    color: 'custom.white',
                    borderRadius: '12px',
                  };
                case 'done-fail':
                  return {
                    ...styles.mapPoint,
                    bgcolor: 'custom.green',
                    borderColor: 'custom.red',
                    color: 'custom.white',
                    borderRadius: '0px',
                  };
                case 'done-fail-pending':
                  return {
                    ...styles.mapPoint,
                    bgcolor: 'custom.green',
                    borderColor: 'custom.red',
                    color: 'custom.yellow',
                    borderRadius: '0px',
                  };
                case 'done-pending':
                  return {
                    ...styles.mapPoint,
                    borderColor: 'custom.green',
                    bgcolor: 'custom.grey',
                    color: 'custom.black',
                    borderRadius: '12px',
                  };
                case 'fail-pending':
                  return {
                    ...styles.mapPoint,
                    bgcolor: 'custom.grey',
                    borderColor: 'custom.red',
                    color: 'custom.black',
                    borderRadius: '0px',
                  };
              }
            };

            return (
              <Marker
                key={`Vehicle In Map Route} ${idx}`}
                longitude={point.coords[0]}
                latitude={point.coords[1]}
              >
                <Grid
                  sx={{
                    ...pointStyle(point.status),
                    transform: `scale(${
                      point?.orderData?.some((p) => p.id === selectedOrderId)
                        ? '1.5'
                        : '1'
                    })`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMapPointClick(point);
                  }}
                >
                  <Typography
                    variant="caption"
                    color="inherit"
                    fontSize={12}
                    fontWeight={600}
                    letterSpacing={1.1}
                    textAlign="center"
                  >
                    {point?.executedOrder || 0}
                  </Typography>
                </Grid>
                {point?.commonUnreadMessages && showUnreadInMap && (
                  <MapUnreadMessagesByPriority
                    unread={point?.commonUnreadMessages?.count || 0}
                    priority={point?.commonUnreadMessages?.priority}
                    customStyle={styles.unreadMessage}
                    bgStyle={styles.bgUnreadMessage}
                    errandId={point.id}
                    onIconClick={
                      point?.popupData?.length
                        ? () => handleMapPointClick(point)
                        : undefined
                    }
                  />
                )}
              </Marker>
            );
          }

          return (
            <Marker
              key={`Vehicle In Map Route} ${idx}`}
              longitude={point.coords[0]}
              latitude={point.coords[1]}
            >
              <Grid
                sx={{
                  ...styles.mapPoint,
                  transform: `scale(${
                    point?.orderData?.some((p) => p.id === selectedOrderId)
                      ? '1.5'
                      : '1'
                  })`,
                }}
                onClick={() => handleMapPointClick(point)}
              >
                <Typography
                  variant="caption"
                  color="custom.black"
                  fontSize={12}
                  fontWeight={600}
                  letterSpacing={1.1}
                  textAlign="center"
                >
                  {point?.order || 0}
                </Typography>
              </Grid>
              {point?.commonUnreadMessages && showUnreadInMap && (
                <MapUnreadMessagesByPriority
                  unread={point?.commonUnreadMessages?.count || 0}
                  priority={point?.commonUnreadMessages?.priority}
                  customStyle={styles.unreadMessage}
                  bgStyle={styles.bgUnreadMessage}
                  errandId={point.id}
                  onIconClick={
                    point?.popupData?.length
                      ? () => handleMapPointClick(point)
                      : undefined
                  }
                />
              )}
              {point?.priority && (
                <Box sx={styles.priorityBlock}>
                  <PriorityToken priority={point.priority} />
                </Box>
              )}
            </Marker>
          );
        });
      }
    }, [
      resources,
      type,
      mapPoints,
      selectedResourceId,
      selectedOrderId,
      showUnreadInMap,
    ]);

    const layerStyle2: LayerProps = {
      id: 'line',
      type: 'line',
      paint: {
        'line-color': '#000000',
        'line-width': 3,
      },
    };

    const fitBoundsMap = useCallback(() => {
      // let preparedCoords = coordinates;
      //
      // if (type === MAP_COMPONENT_TYPE.shipments) {
      //   // preparedCoords = linePoints;
      //   preparedCoords = mapPoints.map((m) => m.coords);
      // }

      // @ts-ignore
      let preparedCoords = coordinates?.map(([lng = 0, lat = 0]) => {
        if (!lng && !lat) {
          return [18.0707, 59.324];
        }

        return [lng, lat];
      });

      if (type === MAP_COMPONENT_TYPE.shipments) {
        // preparedCoords = linePoints;
        preparedCoords = mapPoints.map(
          (m) => [m.coords?.[0] || 18.0707, m.coords[1]] || 59.324,
        );
      }

      const bounds = preparedCoords.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new LngLatBounds(preparedCoords[0], preparedCoords[0]));

      if (bounds && mapRef.current) {
        mapRef.current?.setPadding({
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        });
        mapRef.current?.fitBounds(
          [
            bounds?._ne?.lng || 18.0707,
            bounds?._ne?.lat || 59.324,
            bounds?._sw?.lng || 18.0708,
            bounds?._sw?.lat || 59.325,
          ],
          {
            maxZoom: 12,
          },
        );
      }
    }, [coordinates, mapPoints, type]);

    useEffect(() => {
      if (resources.length || mapPoints.length) {
        timeOutInitial.current = setTimeout(() => fitBoundsMap(), 500);
      }
    }, [type]);

    useEffect(() => {
      if (type === MAP_COMPONENT_TYPE.resources) {
        if (!hasResourcesChanged || !resources.length) return;
      }

      if (type === MAP_COMPONENT_TYPE.shipments) {
        if (!hasMapPointsChanged || !mapPoints.length) return;
      }

      timeOutByDataChange.current = setTimeout(() => fitBoundsMap(), 500);
    }, [
      hasMapPointsChanged,
      mapPoints,
      hasResourcesChanged,
      resources,
      fitBoundsMap,
      type,
    ]);

    useEffect(
      () => () => {
        timeOutInitial.current && clearTimeout(timeOutInitial.current);
        timeOutByDataChange.current &&
          clearTimeout(timeOutByDataChange.current);
      },
      [],
    );

    useEffect(() => {
      mapRef.current?.resize();
    }, [
      currentOrder,
      detailsUnderOrdersStatus,
      selectedResourceId,
      showDetailSection,
    ]);

    const lines = useMemo(() => {
      if (type === MAP_COMPONENT_TYPE.resources) {
        return {};
      }

      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: linePoints,
        },
      };
    }, [linePoints, type]);

    const handleOnMouseEnter = useCallback(() => {
      setCursorStyle('pointer');
    }, []);

    const handleOnMouseLeave = useCallback(() => {
      setCursorStyle(undefined);
    }, []);

    const onHistoricalPointClick = useCallback((event: MapLayerMouseEvent) => {
      if (!event.features || !event.features.length) return;

      const properties = event.features[0].properties;

      if (!properties?.point) {
        return;
      }

      const point = JSON.parse(properties.point);
      setCoords(point.coords);
      setPointClick(null);
      setResourceClick(false);
      setHistoricalClick(point.pointInfo);
      setShowPopup(true);
    }, []);

    const renderOnlyPoints = useMemo(() => {
      if (!historicalPathPoints.length) {
        return null;
      }

      const geojsonPoints = {
        type: 'FeatureCollection',
        features: historicalPathPoints.map((item) => {
          return {
            type: 'Feature',
            properties: {
              point: item,
            },
            geometry: {
              type: 'Point',
              coordinates: item.coords,
            },
          };
        }),
      };

      const pointLayerStyle: LayerProps = {
        id: 'route-point-id',
        type: 'circle',
        interactive: true,
        paint: {
          'circle-radius': 8,
          'circle-color': '#FFA300',
          'circle-stroke-width': 2,
          'circle-stroke-color': '#666666',
        },
      };

      return (
        <Source id="my-points-data" type="geojson" data={geojsonPoints as any}>
          <Layer {...pointLayerStyle} />
        </Source>
      );
    }, [historicalPathPoints]);

    const renderDashDriverLinesPoints = useMemo(() => {
      if (!historicalPathPoints.length) {
        return null;
      }

      const geojsonDashLines = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: historicalPathPoints.map(({ coords }) => coords),
            },
          },
        ],
      };

      const lineLayerStyle: LayerProps = {
        id: 'dash-line-id',
        type: 'line',
        paint: {
          'line-color': '#FFA300',
          'line-width': 4,
          'line-dasharray': [1, 2],
        },
      };

      return (
        <Source
          id="my-dash-lines-data"
          type="geojson"
          data={geojsonDashLines as any}
        >
          <Layer
            {...lineLayerStyle}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
            }}
          />
        </Source>
      );
    }, [historicalPathPoints]);

    return (
      <MapBox
        ref={mapRef}
        initialViewState={{
          longitude: lastMapCenter.longitude,
          latitude: lastMapCenter.latitude,
          zoom: 12,
        }}
        style={{ width: '100%', position: 'relative' }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onClick={onHistoricalPointClick}
        interactiveLayerIds={['route-point-id']}
        cursor={cursorStyle}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onMove={throttle(handleMapMove, 500)}
      >
        {!selectedResourceId?.includes('unassigned') && selectedResourceId && (
          <Box sx={styles.driverPositionGapBlock}>
            <TurnShirtGapTimer />
          </Box>
        )}

        {/* TODO use this for drawing lines */}
        {type === MAP_COMPONENT_TYPE.shipments &&
        selectedResourceId !== UNASSIGNED_TASKS ? (
          <Source id="my-data" type="geojson" data={lines as any}>
            <Layer {...layerStyle2} />
            {markers}
          </Source>
        ) : (
          markers
        )}
        {showPopup && (
          <Popup
            closeOnClick={false}
            key="map-popup"
            longitude={coords[0] || 0}
            latitude={coords[1] || 0}
            anchor="bottom"
            offset={14}
            onClose={() => setShowPopup(false)}
          >
            <>
              {pointClick && (
                <MapPointInfo
                  point={pointClick}
                  closePopup={() => setShowPopup(false)}
                />
              )}
              {resourceClick && (
                <MapTooltip resourceId={localSelectedResource} />
              )}
              {historicalClick && (
                <HistoricalPointInfo point={historicalClick} />
              )}
            </>
          </Popup>
        )}
        {driverHistoricalPath && renderDashDriverLinesPoints}
        {driverHistoricalPath && renderOnlyPoints}
      </MapBox>
    );
  },
);
