import { memo, useMemo } from 'react';
import ClockIcon from '@mui/icons-material/AccessTime';
import CarIcon from '@mui/icons-material/TimeToLeaveOutlined';
import { Box, Divider, List, Stack, SxProps, Typography } from '@mui/material';
import { format } from 'date-fns';
import { sortBy } from 'lodash';
import { useTerminalScansChartQuery } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getSelectedOrderIdSelector } from 'store/selectors/order';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { Order } from 'types/orders';
import { getDifferenceInHoursAndMinutes } from 'utils/timeUtils';

import { InnerSectionListItem } from 'components/common/InnerSectionListItem';
import { InnerSectionPaper } from 'components/common/InnerSectionPaper';
import { getWeightBarcodesObject } from 'components/screens/HomeScreen/components/DetailsSectionInnerTerminal/DetailSectionInnerTerminalUtils';
import { InnerSection } from 'components/ui/InnerSection';
import { TerminalScansChart } from 'components/ui/TerminalScansChart';

import { DetailsSectionInnerOrdersItem } from '../DetailsSectionInnerOrdersItem';

import { styles } from './styles';

interface DetailsSectionInnerTerminalDataProps {
  orders: Order[];
  order: Order;
  beAtTerminalTime: Date | null;
  leaveTerminalTime: Date | null;
}

export const DetailsSectionInnerTerminalData = memo(
  (props: DetailsSectionInnerTerminalDataProps) => {
    const { order, orders, beAtTerminalTime, leaveTerminalTime } = props;
    const currentWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);
    const selectedOrderId = useAppSelector(getSelectedOrderIdSelector);
    const { data: terminalScansData } = useTerminalScansChartQuery(
      {
        work_shift_id: currentWorkshiftId as string,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );

    // @ts-ignore

    const isTerminalReturn = !!order.dropTerminalId;

    const terminalGroupNumber = useMemo(() => {
      if (!selectedOrderId) {
        return 0;
      }

      const splittedGroupNumber = selectedOrderId.split('-')[1];

      if (splittedGroupNumber && !isNaN(Number(splittedGroupNumber))) {
        return Number(splittedGroupNumber);
      }

      return 0;
    }, [selectedOrderId]);

    const currentScansData = useMemo(() => {
      if (!terminalScansData || !terminalScansData.length) {
        return null;
      }

      const foundedTerminalScans = terminalScansData.find(
        ({ terminal_group_number }) =>
          terminal_group_number === terminalGroupNumber,
      );

      if (!foundedTerminalScans) {
        return null;
      }

      return foundedTerminalScans;
    }, [terminalScansData, terminalGroupNumber]);

    const chartData = useMemo(() => {
      if (!currentScansData) {
        return [];
      }

      return currentScansData?.graph || [];
    }, [currentScansData]);

    const alLoaded = useMemo(() => {
      if (!currentScansData) {
        return false;
      }

      return currentScansData?.errands === currentScansData?.finished_errands;
    }, [currentScansData]);

    const preparedOrderList = useMemo(() => {
      if (isTerminalReturn) {
        return orders;
      }

      const ordersWithBarcodes = orders.map((order) => {
        return {
          ...order,
          sortField: getWeightBarcodesObject(
            currentScansData?.errands_scans?.[order.id],
          ),
        };
      });

      return sortBy(ordersWithBarcodes, 'sortField');
    }, [orders, currentScansData]);

    const ordersListContent = useMemo(() => {
      return preparedOrderList.map((order) => (
        <DetailsSectionInnerOrdersItem
          key={order.id}
          order={order}
          isTerminalReturn={isTerminalReturn}
          isTerminal={!order.dropTerminalId}
          barcodes={currentScansData?.errands_scans?.[order.id] || null}
        />
      ));
    }, [preparedOrderList, currentScansData]);

    const beAtTerminalTimeItem = beAtTerminalTime && (
      <InnerSectionListItem
        icon={<ClockIcon />}
        iconColor="custom.green"
        label={t`Be at terminal latest`}
        value={format(beAtTerminalTime, 'HH:mm - dd/MM/yyyy')}
      />
    );

    const leaveTerminalTimeItem = leaveTerminalTime && (
      <InnerSectionListItem
        icon={<CarIcon />}
        iconColor="custom.blue"
        label={t`Leave terminal latest`}
        value={format(leaveTerminalTime, 'HH:mm - dd/MM/yyyy')}
      />
    );

    const errands = currentScansData?.errands || 0;
    const finishedErrands = currentScansData?.finished_errands || 0;
    const scans = currentScansData?.scans || 0;
    const packages = currentScansData?.packages || 0;
    const firstScan = currentScansData?.first_scan
      ? format(new Date(currentScansData?.first_scan), 'HH:mm')
      : '';
    const lastScan = currentScansData?.last_scan
      ? format(new Date(currentScansData?.last_scan), 'HH:mm')
      : '';
    const scanDuration = getDifferenceInHoursAndMinutes(
      currentScansData?.last_scan,
      currentScansData?.first_scan,
    );

    return (
      <InnerSection label={t`Details`} variant="empty" sx={styles.container}>
        <InnerSectionPaper customStyles={{ p: 0 } as SxProps}>
          <Box p="16px">
            <Typography sx={styles.packagesAmount} variant="subtitle2">
              {t`${orders.length} errands`}
            </Typography>
            {(beAtTerminalTime || leaveTerminalTime) && <Divider />}
            {beAtTerminalTimeItem}
            {leaveTerminalTimeItem}
            {!isTerminalReturn && (
              <>
                <Divider sx={styles.divider} />
                <Stack direction="row" justifyContent="space-between" mt="8px">
                  <Typography sx={styles.fieldNameText}>
                    {t`Errand scanned`}
                  </Typography>
                  <Typography sx={styles.fieldValueText}>
                    {finishedErrands}/{errands}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={styles.fieldNameText}>
                    {t`Parcels scanned`}
                  </Typography>
                  <Typography sx={styles.fieldValueText}>
                    {scans}/{packages}
                  </Typography>
                </Stack>
                <Divider sx={styles.divider} />
                <Stack direction="row" justifyContent="space-between" mb="8px">
                  <Typography
                    sx={styles.fieldNameText}
                  >{t`First scan`}</Typography>
                  <Typography sx={styles.fieldValueText}>
                    {firstScan}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" mb="8px">
                  <Typography
                    sx={styles.fieldNameText}
                  >{t`Last scan`}</Typography>
                  <Typography sx={styles.fieldValueText}>{lastScan}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    sx={styles.fieldNameText}
                  >{t`Total scan`}</Typography>
                  <Typography sx={styles.fieldValueText}>
                    {scanDuration}
                  </Typography>
                </Stack>
              </>
            )}
          </Box>
          {!!currentScansData?.scans && !isTerminalReturn && (
            <>
              <Divider />
              <Box p="16px">
                <TerminalScansChart
                  chartData={chartData}
                  allLoaded={alLoaded}
                  errandsQty={currentScansData?.packages || 0}
                />
              </Box>
            </>
          )}
        </InnerSectionPaper>
        <List sx={styles.list}>{ordersListContent}</List>
      </InnerSection>
    );
  },
);
