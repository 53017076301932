import React, {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import {
  Box,
  Button,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ReactComponent as CarIcon } from 'assets/icons/car_without_color.svg';
import { format } from 'date-fns';
import {
  useAllTireReportsQuery,
  useDownloadReportMutation,
} from 'store/api/adminApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setToast } from 'store/reducers/settingsSlice';
import {
  updateTireOrderBy,
  updateTirePage,
  updateTireSize,
} from 'store/reducers/tireReportSlice';
import { getSelectedCompanyIdSelector } from 'store/selectors/company';
import {
  getTireDriverFilter,
  getTireLicenceFilter,
  getTireOrder,
  getTireOrderBy,
  getTirePage,
  getTireResourceFilter,
  getTireSize,
} from 'store/selectors/tireReport';
import { t } from 'ttag';
import { SortOrder } from 'utils/tableUtils';
import { createUniqueId } from 'utils/uniqIdUtils';

import { ReportSelect } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/ReportSelect';
import { TireReportFilterButton } from 'components/ui/TireReportFilterButton';

import { styles } from './styles';
import {
  arrToStr,
  strWithoutMinus,
  tireReportHeadCells,
  TireReportHeadCellTitle,
} from './tireReportTableUtils';

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: TireReportHeadCellTitle,
  ) => void;
  order: SortOrder;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = memo((props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: TireReportHeadCellTitle) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const getBorderStyle = useCallback((isBorder: boolean): SxProps => {
    return isBorder ? styles.rightBorder : {};
  }, []);

  return (
    <TableHead>
      <TableRow>
        {tireReportHeadCells.map((headCell, idx) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              bgcolor: 'custom.grey3',
              ...getBorderStyle(idx < tireReportHeadCells.length - 1),
            }}
          >
            <TableSortLabel
              disabled={!headCell.isSorted}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: '700 !important' }}
                ml={
                  orderBy !== headCell.id && !headCell.numeric ? '16px' : '0px'
                }
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

interface TireReportTableProps {
  setCurrentFeedbackId: React.Dispatch<React.SetStateAction<number | null>>;
  currentReport: string;
  setCurrentReport: Dispatch<SetStateAction<string>>;
}
export const TireReportTable: FC<TireReportTableProps> = React.memo(
  ({ setCurrentFeedbackId, currentReport, setCurrentReport }) => {
    const dispatch = useAppDispatch();
    const orderBy = useAppSelector(getTireOrderBy);
    const order = useAppSelector(getTireOrder);
    const page = useAppSelector(getTirePage);
    const size = useAppSelector(getTireSize);
    const resource_number = useAppSelector(getTireResourceFilter);
    const driver = useAppSelector(getTireDriverFilter);
    const licence_plate = useAppSelector(getTireLicenceFilter);
    const subcontractorId = useAppSelector(getSelectedCompanyIdSelector);

    const { data } = useAllTireReportsQuery(
      {
        order_by: orderBy,
        page,
        size,
        resource_number,
        driver,
        licence_plate,
        subcontractor_id: subcontractorId as string,
      },
      {
        skip: !subcontractorId,
      },
    );

    const [downloadCsv] = useDownloadReportMutation();

    const handleDownload = async () => {
      try {
        // Trigger the mutation to fetch the file
        const blob = await downloadCsv({
          report_type: 'tire-report',
          subcontractor_id: subcontractorId as string,
        }).unwrap();

        // Create an object URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;

        // Set the desired file name
        a.download = `tire-report-${format(
          new Date(),
          'dd-MM-yy--HH-mm-ss',
        )}.csv`;

        // Append the anchor to the body and click it to start download
        document.body.appendChild(a);
        a.click();

        // Remove the anchor and object URL
        a.remove();
        window.URL.revokeObjectURL(url);
        dispatch(
          setToast({
            message: t`Tire report file was downloaded successfully`,
            severity: 'success',
          }),
        );
      } catch (err) {
        console.error('Error downloading the CSV:', err);
      }
    };

    const rows = useMemo(() => {
      if (!data) {
        return [];
      }

      return data?.items?.length ? data.items : [];
    }, [data]);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: TireReportHeadCellTitle,
    ) => {
      // @ts-ignore
      const isAsc = orderBy === property && order === 'asc';
      // @ts-ignore
      dispatch(updateTireOrderBy(isAsc ? `-${property}` : property));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(updateTirePage(newPage + 1));
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      dispatch(updateTireSize(parseInt(event.target.value, 10)));
      dispatch(updateTirePage(1));
    };

    return (
      <Box
        sx={{ width: '100%', mt: '8px', flexGrow: 1, pl: '16px', pr: '16px' }}
      >
        <Box sx={styles.searchBlock}>
          <ReportSelect
            currentReport={currentReport}
            setCurrentReport={setCurrentReport}
          />
          <Stack spacing={2} direction="row">
            <TireReportFilterButton />
            <Button
              onClick={handleDownload}
              sx={styles.button}
              variant="outlined"
              endIcon={<DownloadIcon color="primary" />}
            >
              {t`Export`}
            </Button>
          </Stack>
        </Box>
        <Paper sx={styles.tablePaper}>
          <TableContainer sx={{ maxHeight: '500px' }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={strWithoutMinus(orderBy)}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map((row, index) => {
                  // const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() => setCurrentFeedbackId(row.id)}
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      // tabIndex={-1}
                      key={createUniqueId()}
                      // selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        align="left"
                        variant="body"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            textTransform: 'none',
                            width: '3vw',
                            minWidth: '3vw',
                            maxWidth: '3vw',
                          } as SxProps
                        }
                      >
                        <Box sx={styles.deviceIdBlock}>
                          <CarIcon />
                          <Typography
                            variant="body1"
                            ml="6px"
                            sx={{ lineHeight: '20px' }}
                          >
                            {row.resource_number}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        align="left"
                        variant="body"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            textTransform: 'none',
                            width: '8vw',
                            minWidth: '8vw',
                            maxWidth: '8vw',
                          } as SxProps
                        }
                      >
                        <Box sx={styles.deviceIdBlock}>
                          <PinOutlinedIcon />
                          <Typography
                            variant="body1"
                            ml="6px"
                            sx={{
                              lineHeight: '20px',
                              textTransform: 'none',
                              // display: '-webkit-box',
                              lineClamp: 1,
                              whiteSpace: 'nowrap',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {row.licence_plate}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        align="left"
                        variant="body"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            textTransform: 'none',
                            width: '8vw',
                            minWidth: '8vw',
                            maxWidth: '8vw',
                          } as SxProps
                        }
                      >
                        <Box sx={styles.deviceIdBlock}>
                          <Typography
                            variant="body1"
                            ml="6px"
                            sx={{
                              lineHeight: '20px',
                              textTransform: 'none',
                              // display: '-webkit-box',
                              lineClamp: 1,
                              whiteSpace: 'nowrap',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {row.driver_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '4vw',
                            minWidth: '4vw',
                            maxWidth: '4vw',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {format(
                            new Date(row.created_at as string),
                            'yyyy-MM-dd HH:mm',
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '5vw',
                            minWidth: '5vw',
                            maxWidth: '5vw',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px', textTransform: 'none' }}
                        >
                          {arrToStr(row.pressure)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '5vw',
                            minWidth: '5vw',
                            maxWidth: '5vw',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px', textTransform: 'none' }}
                        >
                          {arrToStr(row.thread_depth)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.total || 0}
            rowsPerPage={size}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={styles.topBorder}
          />
        </Paper>
      </Box>
    );
  },
);
