import { t } from 'ttag';

import { OptionsType } from 'components/ui/Select/SelectWithoutBorder';

export enum COMMON_SETTING {
  upd_ETA_when_older_than_60s = 'upd_ETA_when_older_than_60s',
  upd_ETA_when_errand_complete = 'upd_ETA_when_errand_complete',
  upd_ETA_when_errand_added = 'upd_ETA_when_errand_added',
  upd_ETA_when_errand_removed = 'upd_ETA_when_errand_removed',
  send_GPS_position_in_seconds = 'send_GPS_position_in_seconds',
}

export const commonSettingMapper: Record<COMMON_SETTING, string> = {
  [COMMON_SETTING.upd_ETA_when_older_than_60s]: t`Update ETAs when solution is older than 60s seconds`,
  [COMMON_SETTING.upd_ETA_when_errand_complete]: t`Update ETAs every time an errand has been completed`,
  [COMMON_SETTING.upd_ETA_when_errand_added]: t`Update ETAs every time an errand has been added`,
  [COMMON_SETTING.upd_ETA_when_errand_removed]: t`Update ETAs every time an errand has been removed`,
  [COMMON_SETTING.send_GPS_position_in_seconds]: t`Sending GPS-position`,
};
export enum ETA_SETTING {
  ignore_opter = 'ignore_opter',
  render_complete_ETA = 'render_complete_ETA',
  render_between_gaps = 'render_between_gaps',
  update_by_next_ETA_only = 'update_by_next_ETA_only',
  update_based_at_current = 'update_based_at_current',
  is_strict = 'is_strict',
  update_delivery_order_based_on_ETA = 'update_delivery_order_based_on_ETA',
}

export const etaSettingMapper: Record<ETA_SETTING, string> = {
  [ETA_SETTING.ignore_opter]: t`Ignore ETAs from Opter and produce standalone ETAs from Cityflow/Here`,
  [ETA_SETTING.render_complete_ETA]: t`Renders a complete ETA update for all errands in list`,
  [ETA_SETTING.render_between_gaps]: t`Renders new travel time between the gap and update ETAs`,
  [ETA_SETTING.update_by_next_ETA_only]: t`Update ETAs by asking for ETA to next errand only, and then propagate difference to all following ETAs`,
  [ETA_SETTING.update_based_at_current]: t`Update ETAs by asking for updated ETAs for all errands based on current errand order`,
  [ETA_SETTING.is_strict]: t`Set strict/non-strict mode for terminal return`,
  [ETA_SETTING.update_delivery_order_based_on_ETA]: t`Update delivery order numbers based on ETA`,
};

export enum ERRAND_SETTINGS {
  ignore_opter_order = 'ignore_opter_order',
  manual_order = 'manual_order',
  manual_optimization = 'manual_optimization',
  auto_optimization = 'auto_optimization',
  use_global_provider = 'use_global_provider',
  use_here_sdk = 'use_here_sdk',
  use_cityflow = 'use_cityflow',
  ignore_terminal_delivery = 'ignore_terminal_delivery',
  optimize_add_errand = 'optimize_add_errand',
  optimize_del_errand = 'optimize_del_errand',
  optimize_errand_picked_meters = 'optimize_errand_picked_meters',
  optimize_errand_picked_number = 'optimize_errand_picked_number',
  use_opter_data_for_returns = 'use_opter_data_for_returns',
  disable_push_not_finished_shipments = 'disable_push_not_finished_shipments',
  skip_scan_barcode = 'skip_scan_barcode',
  ignore_terminal_task_splitting = 'ignore_terminal_task_splitting',
}

export const errandSettingMapper: Record<ERRAND_SETTINGS, string> = {
  [ERRAND_SETTINGS.ignore_opter_order]: t`Ignore errand delivery order from Opter/PTV and any delivery order number updates`,
  [ERRAND_SETTINGS.manual_order]: t`Enable/Disable manual errand delivery order by web application user`,
  [ERRAND_SETTINGS.manual_optimization]: t`Enable/Disable manual optimization of errand delivery order by web application user`,
  [ERRAND_SETTINGS.auto_optimization]: t`Enable/Disable automatic optimization of errand delivery order by standalone module `,
  [ERRAND_SETTINGS.use_global_provider]: t`Use global provider`,
  [ERRAND_SETTINGS.use_here_sdk]: t`Use Here SDK provider`,
  [ERRAND_SETTINGS.use_cityflow]: t`Use City Flow provider`,
  [ERRAND_SETTINGS.ignore_terminal_delivery]: t`Ignore terminal “delivery errands” in the optimization.`,
  [ERRAND_SETTINGS.optimize_add_errand]: t`Re-optimise after errand has been assigned to the work shift`,
  [ERRAND_SETTINGS.optimize_del_errand]: t`Re-optimise after errand has been removed from the work shift`,
  [ERRAND_SETTINGS.optimize_errand_picked_meters]: t`Re-optimise if other than the next errand in list is picked up/delivered`,
  [ERRAND_SETTINGS.optimize_errand_picked_number]: t`Re-optimise if next errand in list is picked up/delivered & errand done was < x meter from “previous errand or next errand”`,
  [ERRAND_SETTINGS.use_opter_data_for_returns]: t`Use Opter's data for return flow`,
  [ERRAND_SETTINGS.disable_push_not_finished_shipments]: t`Disable push not finished shipments to the current shift`,
  [ERRAND_SETTINGS.skip_scan_barcode]: t`Skip scan packages screen`,
  [ERRAND_SETTINGS.ignore_terminal_task_splitting]: t`Ignore terminal task splitting`,
};

export const setGpsPositionOptions: OptionsType[] = [
  {
    value: '15',
    label: '15 sec',
  },
  {
    value: '30',
    label: '30 sec',
  },
  {
    value: '45',
    label: '45 sec',
  },
  {
    value: '60',
    label: '60 sec',
  },
];
