import { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Stack,
  Typography,
} from '@mui/material';
import { SelectOption } from 'types/common';

// @ts-ignore
export interface SelectProps extends MuiSelectProps {
  options?: SelectOption[];
  label?: string;
  helperText?: string;
  required?: boolean;
}

export const Select: FC<SelectProps> = ({
  options,
  label,
  helperText,
  required,
  ...rest
}) => {
  const content = options?.map(({ value, label, img_name }) => (
    <MenuItem key={value} value={value}>
      {!!img_name && (
        <Stack direction="row" alignItems="center" gap={2}>
          <img src={img_name} width={16} height={30} alt="chasis type image" />
          <Typography>{label}</Typography>
        </Stack>
      )}
      {!img_name && label}
    </MenuItem>
  ));

  return (
    <FormControl required={required} fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        {...rest}
        required={required}
        label={label}
        displayEmpty
        // @ts-ignore
        value={rest?.value || ''}
      >
        {content}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
