import { memo, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Token } from 'assets/icons/common_priority_token.svg';
import { ErrandPriorityType } from 'types/api';

import { styles } from './styles';

interface PriorityTokenProps {
  priority: ErrandPriorityType | null;
}
export const PriorityToken = memo((props: PriorityTokenProps) => {
  const { priority } = props;

  const color = useMemo(() => {
    switch (priority) {
      case '0':
      default:
        return '#DA291C';
      case '1':
        return '#FF8200';
      case '2':
        return '#42A146';
      case '3':
        return '#1FD655';
    }
  }, [priority]);

  const textColor = useMemo(() => {
    switch (priority) {
      case '0':
      default:
        return 'custom.white';
      case '1':
        return 'custom.white';
      case '2':
        return 'custom.black';
      case '3':
        return 'custom.black';
    }
  }, [priority]);

  if (!priority || priority === '-1') {
    return null;
  }
  return (
    <Box sx={styles.container}>
      <Token fill={color} />
      <Box sx={styles.priorityTextBlock}>
        <Typography sx={{ ...styles.priorityText, color: textColor }}>
          P{priority}
        </Typography>
      </Box>
    </Box>
  );
});
