import { useCallback, useMemo } from 'react';
import { UNASSIGNED_TASKS } from 'constants/common';
import { useShipmentsByResourceIdQuery } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getSelectedOrderIdSelector,
  getUnassignedShipments,
} from 'store/selectors/order';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';

export const useSelectedShipment = (orderId?: string | null) => {
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);

  const selectedOrderId =
    orderId !== undefined
      ? orderId
      : useAppSelector(getSelectedOrderIdSelector);
  const unassignedShipments = useAppSelector(getUnassignedShipments);

  const { data: listByResource, isFetching: isListFetching } =
    useShipmentsByResourceIdQuery(
      {
        resourceId: selectedResourceId as string,
        workshiftId: selectedWorkshiftId as string,
      },
      {
        skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS,
      },
    );

  // const { data: unassignedShipments, isFetching: unassignedIsFetching } =
  //   useUnassignedShipmentsQuery(
  //     selectedResourceId === UNASSIGNED_TASKS ? undefined : skipToken,
  //   );

  const { shipmentsList, isShipmentFetching } = useMemo(() => {
    if (selectedResourceId === UNASSIGNED_TASKS) {
      return {
        shipmentsList: unassignedShipments,
        isShipmentFetching: false,
      };
    }

    return {
      shipmentsList: listByResource,
      isShipmentFetching: isListFetching,
    };
  }, [selectedResourceId, unassignedShipments, listByResource, isListFetching]);

  const getSelectedShipment = useCallback(() => {
    if (!shipmentsList?.length) {
      return null;
    }

    const shipment = shipmentsList.find(
      (shipment) =>
        shipment.pickup_errand_id === selectedOrderId ||
        shipment.delivery_errand_id === selectedOrderId,
    );

    return shipment || null;
  }, [selectedOrderId, shipmentsList]);

  const selectedShipment = useMemo(
    () => getSelectedShipment(),
    [getSelectedShipment],
  );

  return { selectedShipment, isFetching: isShipmentFetching };
};
