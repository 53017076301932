import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCustomBackHandler = (onBack: () => boolean): void => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      if (!onBack()) {
        // Block navigation
        return;
      }
      // Proceed with back navigation
      navigate(-1);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, onBack]);
};
