import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    display: 'flex',
    mt: '24px',
    p: '16px',
  },
  qrCodeContainer: {
    display: 'flex',
    width: '255px',
    height: '255px',
    padding: '30px',
    bgcolor: 'custom.white',
  },
  companyName: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '38px',
  },
  companyDetails: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '385px',
    flexGrow: 1,
  },
  cityBlock: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  qrWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '255px',
    ml: '64px',
  },

  regenerateButton: {
    mt: '24px',
    width: '100%',
  },

  submitButton: {
    mt: '24px',
    width: '200px',
  },

  input: {
    bgcolor: 'custom.white',
  },
};
