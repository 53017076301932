import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import {
  useDeactivateAllDevicesByCompanyIdMutation,
  useDevicesByCompanyIdQuery,
  useEditDevicesByCompanyIdMutation,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setToast } from 'store/reducers/settingsSlice';
import { getSelectedCompanyIdSelector } from 'store/selectors/company';
import { t } from 'ttag';
import { DriverDevice } from 'types/api';
import {
  getComparator,
  headCells,
  SortOrder,
  stableSort,
} from 'utils/tableUtils';

import { CustomInputOnHover } from 'components/ui/CustomInputOnHover';
import { DialogModal } from 'components/ui/DialogModal';

import { styles } from './styles';

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DriverDevice,
  ) => void;
  order: SortOrder;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DriverDevice) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const getBorderStyle = useCallback((isBorder: boolean): SxProps => {
    return isBorder ? styles.rightBorder : {};
  }, []);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, idx) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              bgcolor: 'custom.grey3',
              ...getBorderStyle(idx < headCells.length - 1),
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography
                variant="body2"
                ml={
                  orderBy !== headCell.id && !headCell.numeric ? '16px' : '0px'
                }
                sx={{ fontWeight: '700 !important' }}
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface DevicesCompanyDetailSectionProps {
  order: SortOrder;
  setOrder: Dispatch<SetStateAction<SortOrder>>;
  orderBy: keyof DriverDevice;
  setOrderBy: Dispatch<SetStateAction<keyof DriverDevice>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  deviceFilter: string;
  setDeviceFilter: Dispatch<SetStateAction<string>>;
  isCompanyActive: boolean;
}

export const DevicesCompanyDetailSection: FC<DevicesCompanyDetailSectionProps> =
  React.memo(
    ({
      order,
      setOrder,
      orderBy,
      setOrderBy,
      page,
      setPage,
      rowsPerPage,
      setRowsPerPage,
      deviceFilter,
      setDeviceFilter,
      isCompanyActive,
    }) => {
      const selectedCompanyId = useAppSelector(getSelectedCompanyIdSelector);
      const dispatch = useAppDispatch();
      const { data: devicesList } = useDevicesByCompanyIdQuery(
        selectedCompanyId as string,
        {
          skip: !selectedCompanyId,
        },
      );
      const [updateDevice, { isLoading: switcherUpdateLoading }] =
        useEditDevicesByCompanyIdMutation();
      const [deactivateAll, { isLoading: isDeactivatedAllLoading }] =
        useDeactivateAllDevicesByCompanyIdMutation();

      // const [dense, setDense] = useState(false);

      const [devices, setDevices] = useState<DriverDevice[]>([]);
      const [openModal, setOpenModal] = useState(false);

      useEffect(() => {
        if (devicesList) {
          setDevices(devicesList);
        }
      }, [devicesList]);

      const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setDeviceFilter(e.target.value);
        },
        [],
      );

      const handleModalConfirm = useCallback(() => {
        deactivateAll(selectedCompanyId as string);
      }, [selectedCompanyId]);

      const handleDeactivatePress = useCallback(() => {
        setOpenModal(true);
      }, []);

      const handleCloseClick = useCallback(() => {
        setDeviceFilter('');
      }, []);

      const handleChangeSwitcher = useCallback(
        (id: string, currentValue: boolean) => {
          if (switcherUpdateLoading) {
            return;
          }

          setDevices((prev) => {
            return prev.map((dd) => {
              if (dd.id === id) {
                return {
                  ...dd,
                  is_active: !dd.is_active,
                };
              }

              return dd;
            });
          });

          updateDevice({
            companyId: selectedCompanyId as string,
            deviceId: id,
            is_active: !currentValue,
          });
        },
        [switcherUpdateLoading, selectedCompanyId],
      );

      const handleChangeNote = (id: string) => (currentValue: string) => {
        if (switcherUpdateLoading) {
          return;
        }

        setDevices((prev) => {
          return prev.map((dd) => {
            if (dd.id === id) {
              return {
                ...dd,
                notes: currentValue,
              };
            }

            return dd;
          });
        });

        updateDevice({
          companyId: selectedCompanyId as string,
          deviceId: id,
          notes: currentValue,
        });

        dispatch(
          setToast({
            message: t`Comment was updated successfully`,
            severity: 'success',
          }),
        );
      };

      const rows = useMemo(() => {
        if (!deviceFilter) {
          return devices?.length ? devices : [];
        }

        return devices?.length
          ? devices.filter((d) => d.device_id.includes(deviceFilter))
          : [];
      }, [devices, deviceFilter]);

      const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof DriverDevice,
      ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

      const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };

      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
      //   setDense(event.target.checked);
      // };

      // Avoid a layout jump when reaching the last page with empty rows.
      // const emptyRows = useMemo(() => {
      //   return page > 0
      //     ? Math.max(0, (1 + page) * rowsPerPage - rows.length)
      //     : 0;
      // }, [rows.length, page, rowsPerPage]);

      const visibleRows = React.useMemo(
        () =>
          stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
          ),
        [order, orderBy, page, rowsPerPage, rows],
      );

      return (
        <>
          <Box sx={{ width: '100%', p: '16px' }}>
            <Box sx={styles.searchBlock}>
              <TextField
                sx={{ maxWidth: '350px', bgcolor: 'custom.white' }}
                onChange={handleInputChange}
                placeholder={t`Search by device ID`}
                value={deviceFilter}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: 0 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleCloseClick}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type="text"
              />
              <Button
                onClick={handleDeactivatePress}
                sx={{ maxWidth: '200px' }}
                variant="outlined"
                disabled={isDeactivatedAllLoading || !isCompanyActive}
              >
                {t`Deactivate all`}
              </Button>
            </Box>
            <Paper sx={styles.tablePaper}>
              <TableContainer sx={{ maxHeight: '500px' }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      // const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.id)}
                          // role="checkbox"
                          // aria-checked={isItemSelected}
                          // tabIndex={-1}
                          key={row.id as string}
                          // selected={isItemSelected}
                          // sx={{ cursor: 'pointer' }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            // scope="row"
                            align="left"
                            variant="body"
                            // padding="none"
                            sx={
                              {
                                ...styles.zeroBorder,
                                ...styles.rightBorder,
                                textTransform: 'none',
                              } as SxProps
                            }
                          >
                            <Box sx={styles.deviceIdBlock}>
                              <PhoneIphoneIcon
                                sx={{
                                  color:
                                    row.is_active && isCompanyActive
                                      ? 'custom.blueLight2'
                                      : 'custom.grey',
                                }}
                              />
                              <Typography
                                variant="body1"
                                ml="6px"
                                sx={{ lineHeight: '20px' }}
                              >
                                {row.device_id}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            // scope="row"
                            align="left"
                            variant="body"
                            // padding="none"
                            sx={
                              {
                                ...styles.zeroBorder,
                                ...styles.rightBorder,
                                textTransform: 'none',
                              } as SxProps
                            }
                          >
                            <Box sx={styles.deviceIdBlock}>
                              <CustomInputOnHover
                                key={row.device_id}
                                onPressOutside={handleChangeNote(row.id)}
                                value={row.notes}
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={
                              {
                                ...styles.zeroBorder,
                                ...styles.rightBorder,
                              } as SxProps
                            }
                          >
                            <Typography
                              variant="body1"
                              ml="6px"
                              sx={{ lineHeight: '20px' }}
                            >
                              {format(
                                new Date(row.created_at as string),
                                'yyyy-MM-dd',
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={
                              {
                                ...styles.zeroBorder,
                                ...styles.rightBorder,
                              } as SxProps
                            }
                          >
                            <Typography
                              variant="body1"
                              ml="6px"
                              sx={{ lineHeight: '20px' }}
                            >
                              {format(
                                new Date(row.last_login as string),
                                'yyyy-MM-dd',
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" sx={styles.zeroBorder}>
                            <Switch
                              name="adr"
                              id="adr"
                              disabled={!isCompanyActive}
                              value={isCompanyActive ? row.is_active : false}
                              checked={isCompanyActive ? row.is_active : false}
                              onChange={() =>
                                handleChangeSwitcher(
                                  row.id as string,
                                  row.is_active as boolean,
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && ( */}
                    {/*   <TableRow */}
                    {/*     style={{ */}
                    {/*       height: 33 * emptyRows, */}
                    {/*     }} */}
                    {/*   > */}
                    {/*     <TableCell colSpan={4} /> */}
                    {/*   </TableRow> */}
                    {/* )} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={styles.topBorder}
              />
            </Paper>
          </Box>
          <DialogModal
            open={openModal}
            onConfirm={handleModalConfirm}
            onClose={() => setOpenModal(false)}
            title={t`Are you sure you want to deactivate all current devices?`}
            content={t`All drivers won’t be able to use the Mobile Application`}
          />
        </>
      );
    },
  );
