import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  switchItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toggleButton: {
    width: '40px',
  },
  red: {
    color: 'custom.red',
    fontWeight: 700,
  },
  blue: {
    color: 'custom.lightGreen',
    fontWeight: 700,
  },
  orange: {
    color: 'primary.main',
    fontWeight: 700,
  },
  purple: {
    color: 'custom.green',
    fontWeight: 700,
  },
  toggleGroup: {
    '& .Mui-selected': {
      bgcolor: 'custom.orangeLight',
    },
  },
};
